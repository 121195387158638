import React from 'react';
import PropTypes from 'prop-types';

// Styles
import combineStyles from '../../../utilities/combine_styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';


/**
 * Header
 */
class Header extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {author, date, title, type, classes, mode} = this.props;
    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.headerBackgroundDark
            : classes.headerBackgroundLight
        }
      >
        <p
          className={
            mode.theme.enableDarkMode
              ? classes.dateStampDark
              : classes.dateStampLight
          }
        >
          {date} / {type}
        </p>
        <p
          className={
            mode.theme.enableDarkMode ? classes.titleDark : classes.titleLight
          }
        >
          {title}
        </p>
        <p
          className={
            mode.theme.enableDarkMode ? classes.authorDark : classes.authorLight
          }
        >
          {author}
        </p>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};


const combinedStyles = combineStyles(darkStyles, lightStyles);

export default withStyles(combinedStyles)(Header);
