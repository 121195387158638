import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  rootLight: {
    paddingTop: 16,
    paddingBottom: 16,
    flexGrow: 1,
    backgroundColor: colors.primary,
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
      textAlign: 'center',
    },
  },
  linkLight: {
    'textDecoration': 'none',
    'fontFamily': fonts.base,
    'fontSize': 14,
    'color': '#A6B0D4',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bitzTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 25,
    color: colors.white,
    width: 70,
  },
  bitzSloganLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#FFF',
    marginTop: 10,
    marginBottom: 0,
  },
  bitzMessageLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#FFF',
    marginTop: 0,
  },
  linkTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#FFF',
  },
  heartIconLight: {
    '&:hover': {
      backgroundColor: '#6067F1 !important',
      cursor: 'default',
    },
    'color': '#F55F44',
    'marginBottom': 5,
    'width': 30,
  },
});

export default styles;
