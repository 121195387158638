import {fonts, colors} from '../../../theme';

const styles = (theme) => ({
  heroBackgroundDark: {
    backgroundColor: colors.darkThemeMediumGreyBackground,
    paddingTop: 70,
    paddingBottom: 0,
  },
  heroBackgroundLight: {
    backgroundColor: '#F3F7FE',
    paddingTop: 70,
    paddingBottom: 0,
  },
  heroHeadlineDark: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
    marginLeft: 20,
    marginRight: 20,
  },
  heroHeadlineLight: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    display: 'block',
    margin: 'auto',
    marginLeft: 20,
    marginRight: 20,
  },
  heroMissionDark: {
    marginTop: 10,
    maxWidth: 540,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
    marginBottom: 50,
  },
  heroMissionLight: {
    marginTop: 10,
    maxWidth: 540,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#002B2B',
    marginBottom: 50,
  },
  heroVector: {
    margin: 'auto',
    display: 'block',
    width: '100%',
  },
  heroVectorMobile: {
    margin: 'auto',
    display: 'block',
    width: '85%',
  },
  heroImage: {
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
});

export default styles;
