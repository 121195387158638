/* eslint-disable max-len */
import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

// Get all the routes
import indexRoutes from './routes/index';

// Redux
import thunk from 'redux-thunk';
import allReducers from './reducers/index.js';
import {
  createStore,
  applyMiddleware,
} from 'redux';

// eslint-disable-next-line no-unused-vars
import {
  Provider,
} from 'react-redux';

const store = createStore(allReducers, applyMiddleware(thunk));

/**
 * App
 * @Todo - Create a routes.js page to consolidate switch
 */
class App extends Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <Router>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route path={prop.path} component={prop.component} key={key} />
                );
              })}
            </Switch>
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
