const styles = (theme) => ({
  huntClubCard: {
    padding: 20,
    borderRadius: 10,
    maxWidth: 300,
    display: 'block',
    margin: 'auto',
  },
  projectImage: {
    'maxWidth': 302,
    'height': 200,
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
  },
  storeCard: {
    margin: 'auto',
    display: 'block',
  },
});

export default styles;
