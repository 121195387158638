/* eslint-disable max-len */
import React from 'react';
import IntroContainer from '../../containers/posts/intro';
import {Helmet} from 'react-helmet';

/**
 * Intro
 */
class Intro extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Introduction - Bitz LLC</title>
          <link
            rel="canonical"
            href="http://brandonfitzwater.com/blog/introduction"
          />
          <meta
            name="description"
            content="My mission is to not only provide a quality overview of each topic, but to help those who might not know where to start begin to dabble in a particular topic."
          />
          <meta
            name="keywords"
            content="blog introduction, bitz mission, blog topics, learn technology, how to machine learning, coding tutorials"
          />
        </Helmet>
        <IntroContainer />
      </div>
    );
  }
}

export default Intro;
