import {fonts, colors} from '../../../theme';

const styles = (theme) => ({
  heroBackgroundLight: {
    backgroundColor: '#F3F7FE',
    paddingTop: 70,
    paddingBottom: 70,
  },
  heroBackgroundDark: {
    backgroundColor: colors.darkThemeMediumGreyBackground,
    paddingTop: 70,
    paddingBottom: 70,
  },
  contactDark: {
    'backgroundColor': colors.darkPrimary,
    'boxShadow': 'none',
    'display': 'block',
    'marginTop': 30,
    'height': 45,
    'width': 120,
    'borderRadius': 30,
    '&:hover': {
      backgroundColor: colors.darkPrimary,
    },
  },
  contactLight: {
    'backgroundColor': '#6067F1',
    'boxShadow': '0 11px 30px rgba(97, 108, 238, .25)',
    'display': 'block',
    'marginTop': 30,
    'height': 45,
    'width': 120,
    'borderRadius': 30,
    '&:hover': {
      backgroundColor: '#6168e6',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#FFF',
    fontFamily: fonts.base,
    fontSize: 12,
  },
  heroHeadlineLight: {
    marginTop: 0,
    maxWidth: 460,
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    display: 'block',
    margin: 'auto',
  },
  heroHeadlineDark: {
    marginTop: 0,
    maxWidth: 460,
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
  },
  heroHeadlineMobileDark: {
    marginTop: 0,
    fontSize: 34,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
    marginLeft: 30,
  },
  heroHeadlineMobileLight: {
    marginTop: 0,
    fontSize: 34,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    display: 'block',
    margin: 'auto',
    marginLeft: 30,
  },
  heroMissionDark: {
    marginTop: 30,
    maxWidth: 460,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
  },
  heroMissionLight: {
    marginTop: 30,
    maxWidth: 460,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#9198B2',
  },
  heroMissionMobileDark: {
    marginTop: 30,
    maxWidth: 350,
    marginLeft: 30,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#E1E1E4',
  },
  heroMissionMobileLight: {
    marginTop: 30,
    maxWidth: 350,
    marginLeft: 30,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#9198B2',
  },
  hero: {
    marginTop: 30,
    height: 500,
    overflow: 'hidden',
  },
  heroVector: {
    margin: 'auto',
    display: 'block',
    width: '100%',
  },
  heroVectorMobile: {
    margin: 'auto',
    display: 'block',
    width: 300,
  },
  heroImage: {
    display: 'block',
    margin: 'auto',
  },
  heroImageMobile: {
    display: 'block',
    margin: 'auto',
    height: 100,
  },
});

export default styles;
