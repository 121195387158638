import {fonts, colors} from '../../../../../theme';
import backgroundPrimary from '../../../../../images/lines_circles.svg';

const styles = (theme) => ({
  bitzRocket: {
    width: 30,
    marginTop: 25,
  },
  storeCard: {
    margin: 'auto',
    display: 'block',
  },
  card: {
    borderRadius: 10,
    maxWidth: 300,
    display: 'block',
    margin: 'auto',
  },
  cardImageAlt: {
    'backgroundImage': `url(${backgroundPrimary})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundColor': '#38D39F',
    'maxWidth': 302,
    'height': 200,
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
  },
  headline: {
    color: '#e0dfe0',
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  shortDescription: {
    color: '#e0dfe0',
    fontFamily: fonts.base,
    marginTop: 0,
    fontSize: 14,
  },
  title: {
    color: colors.white,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    fontSize: 24,
    marginBottom: 0,
  },
  titleGreen: {
    color: '#A5D6A7',
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    fontSize: 24,
    marginBottom: 0,
  },
  titlePurple: {
    color: colors.darkPrimary,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    fontSize: 24,
    marginBottom: 0,
  },
});

export default styles;
