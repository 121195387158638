/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Footer from '../../footer/about/footer';
import Header from '../../posts/header/header';
import NavBar from '../../app_bar/appbar';

// Visualization
import {
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  XAxis,
  XYPlot,
  YAxis,
  MarkSeries,
} from 'react-vis';
import MathJax from 'react-mathjax2';
import kangarooData from '../../../data/kangaroo';

// Theming
import combineStyles from '../../../utilities/combine_styles';
import {withStyles} from '@material-ui/core/styles';
import commonPostStyles from '../../../theme/posts/common/styles';
import darkPostStyles from '../../../theme/posts/dark/styles';
import lightPostStyles from '../../../theme/posts/light/styles';
import dialogStylesDark from '../../../theme/dialogs/dark/styles';
import dialogStylesLight from '../../../theme/dialogs/light/styles';
import {colors, fonts} from '../../../theme';

// Images
import logoDark from '../../../images/bitz_logo_green.svg';
import logoLight from '../../../images/bitz_logo_alt.svg';

// Header info
const date = 'May 27, 2019';
const type = 'Developer';
const title = 'Linear Regression and Grey Kangaroos';
const author = 'Brandon Fitzwater';

/**
 * Linear Regression
 */
class Regression extends React.Component {
  state = {
    height: 0,
    width: 0,
    open: false,
    dialogTitle: '',
    dialogBody: '',
  };

  /**
   * Lifecyle method
   */
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * Lifecyle method
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * @Function handleClose
   * @Purpose closes terminology dialog
   */
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  /**
   * @Function updateWindowDimensions
   * @Purpose updates width and height of the window
   */
  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  /**
   * @Function openDialog
   * @Purpose opens generic dialog
   * @param {String} title title of dialog
   * @param {String} body message to display in body
   */
  setTerminologyText = (title, body) => {
    this.setState({
      open: true,
      dialogTitle: title,
      dialogBody: body,
    });
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogDark
                : classes.dialogLight
            }
          >
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogTitleDark
                  : classes.dialogTitleLight
              }
            >
              {this.state.dialogTitle}
            </p>
          </DialogTitle>
          <DialogContent
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogDark
                : classes.dialogLight
            }
          >
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogDescriptionDark
                  : classes.dialogDescriptionLight
              }
            >
              {this.state.dialogBody}
            </p>
          </DialogContent>
          <DialogActions
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogFooterDark
                : classes.dialogFooterLight
            }
          >
            <Button
              onClick={this.handleClose}
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogButtonTextDark
                  : classes.dialogButtonTextLight
              }
              autoFocus
            >
              Got it
            </Button>
          </DialogActions>
        </Dialog>
        <NavBar mode={this.props} />
        <Header
          date={date}
          type={type}
          title={title}
          author={author}
          mode={this.props}
        />
        <Grid
          container
          spacing={24}
          className={
            this.props.theme.enableDarkMode
              ? classes.backgroundHeroDark
              : classes.backgroundHeroLight
          }
        >
          <Grid item xs={12}>
            <div>
              <div className={classes.card}>
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.cardImageDarkGrey
                      : classes.cardImagePrimary
                  }
                >
                  <img
                    className={classes.bitzRocket}
                    alt="Bitz LLC rocket logo"
                    src={this.props.theme.enableDarkMode ? logoDark : logoLight}
                  />
                  <p className={classes.headline}>Machine Learning</p>
                  <p
                    className={
                      this.props.theme.enableDarkMode
                        ? classes.titleGreen
                        : classes.title
                    }
                  >
                    Linear Regression
                  </p>
                  <p className={classes.shortDescription}>Grey Kangaroos</p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          className={
            this.props.theme.enableDarkMode
              ? classes.backgroundDark
              : classes.backgroundLight
          }
        >
          <div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.titleDark
                  : classes.titleLight
              }
            >
              Introduction
            </p>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              Australian zoologists study all sorts of animals, but one
              relationship they have noticed in particular is that between the
              grey kangaroos nose width and its nose length. While vacationing
              in the land down under, you encounter a grey kangaroo and decide
              to explore this connection.
            </p>
          </div>
          <div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.secondaryHeaderDark
                  : classes.secondaryHeaderLight
              }
            >
              Linear Regression
            </p>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              Let's take a look at the data you've collected.
            </p>
            <div className={classes.chartContainer}>
              <XYPlot
                margin={{bottom: 50, left: 50, right: 10, top: 20}}
                width={this.state.width < 400 ? 300 : 400}
                height={this.state.width < 400 ? 300 : 400}
                stroke={this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'}
                fill={this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'}
              >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis
                  tickSize={3}
                  tickValues={[600, 650, 700, 750]}
                  style={{
                    line: {
                      stroke: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                      fill: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                    },
                    ticks: {
                      stroke: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                      fill: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                    },
                    text: {
                      stroke: 'none',
                      fill: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                      fontFamily: fonts.base,
                    },
                    title: {
                      fill: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : '#333d',
                    },
                  }}
                  color={
                    this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'
                  }
                  title="Nasal Length (mm)"
                />
                <YAxis
                  tickSize={3}
                  tickValues={[220, 240, 260, 280]}
                  style={{
                    line: {
                      stroke: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                    },
                    ticks: {
                      stroke: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                    },
                    text: {
                      stroke: 'none',
                      fill: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : colors.lightThemeDarkBlue,
                      fontFamily: fonts.base,
                    },
                    title: {
                      fill: this.props.theme.enableDarkMode
                        ? colors.darkThemeWhiteText
                        : '#333d',
                    },
                  }}
                  color={
                    this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'
                  }
                  title="Nasal Width (mm)"
                />
                <MarkSeries
                  className="mark-series-example"
                  strokeWidth={1}
                  opacity="0.8"
                  sizeRange={[5, 15]}
                  data={kangarooData}
                />
              </XYPlot>
            </div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              From the graph above you can see that as the kangaroo's nose gets
              longer, so does the width of the nose. This is indeed a linear
              relationship and you could even draw a line of best fit to
              approximate this relationship.
            </p>
          </div>
          <div className={classes.chartContainer}>
            <XYPlot
              margin={{bottom: 50, left: 50, right: 10, top: 20}}
              width={this.state.width < 400 ? 300 : 400}
              height={this.state.width < 400 ? 300 : 400}
              stroke={this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'}
              fill={this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'}
            >
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis
                tickSize={3}
                tickValues={[600, 650, 700, 750]}
                style={{
                  line: {
                    stroke: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                    fill: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                  },
                  ticks: {
                    stroke: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                    fill: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                  },
                  text: {
                    stroke: 'none',
                    fill: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                    fontFamily: fonts.base,
                  },
                  title: {
                    fill: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : '#333d',
                  },
                }}
                color={this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'}
                title="Nasal Length (mm)"
              />
              <YAxis
                tickSize={3}
                tickValues={[220, 240, 260, 280]}
                style={{
                  line: {
                    stroke: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                  },
                  ticks: {
                    stroke: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                  },
                  text: {
                    stroke: 'none',
                    fill: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : colors.lightThemeDarkBlue,
                    fontFamily: fonts.base,
                  },
                  title: {
                    fill: this.props.theme.enableDarkMode
                      ? colors.darkThemeWhiteText
                      : '#333d',
                  },
                }}
                color={this.props.theme.enableDarkMode ? '#A5D6A7' : '#6067F1'}
                title="Nasal Width (mm)"
              />
              <LineSeries
                data={[{x: 560, y: 210}, {x: 780, y: 280}]}
                strokeStyle="dashed"
                stroke={
                  this.props.theme.enableDarkMode
                    ? colors.darkThemeWhiteText
                    : colors.lightThemeDarkGrey
                }
                strokeWidth={1}
              />
              <MarkSeries
                className="mark-series-example"
                strokeWidth={1}
                opacity="0.8"
                sizeRange={[5, 15]}
                data={kangarooData}
              />
            </XYPlot>
          </div>
          <div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              While our approximation doesn't hit every point, the line does
              model the relationship between the nasal length and width of the
              grey kangaroo's. Now, let's rewind a few years ago back to math
              class where you learned the equation for a line. This equation
              describes our best fit line!
            </p>
            <div
              className={
                this.props.theme.enableDarkMode
                  ? classes.formulaDark
                  : classes.formulaLight
              }
            >
              <MathJax.Context input="ascii">
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.formulaDark
                      : classes.formulaLight
                  }
                >
                  <MathJax.Node>y = mx + b</MathJax.Node>
                </div>
              </MathJax.Context>
            </div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              If this still doesn't ring a bell, let's quickly identify each
              variable in the equation as it compares to our kangaroo example as
              follows:
            </p>
            <div
              className={
                this.props.theme.enableDarkMode
                  ? classes.unorderedListDark
                  : classes.unorderedListLight
              }
            >
              <ul>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>y</MathJax.Node>
                      </b>
                      {'  '}is the nasal width (mm) of the kangaroo. This is the
                      value we are trying to predict.
                    </div>
                  </MathJax.Context>
                </li>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>m</MathJax.Node>
                      </b>
                      {'  '}is the slope of the line. Remember the trick slope =
                      rise over run.
                    </div>
                  </MathJax.Context>
                </li>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>x</MathJax.Node>
                      </b>
                      {'  '}is the nasal length (mm) of the kangaroo.
                    </div>
                  </MathJax.Context>
                </li>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>b</MathJax.Node>
                      </b>
                      {'  '}is the y intercept.
                    </div>
                  </MathJax.Context>
                </li>
              </ul>
            </div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              In machine learning the notation typically appears a bit
              different, but don't let that scare you. The equation for a model
              might look something like this:
            </p>
            <div
              className={
                this.props.theme.enableDarkMode
                  ? classes.formulaDark
                  : classes.formulaLight
              }
            >
              <MathJax.Context input="ascii">
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.formulaDark
                      : classes.formulaLight
                  }
                >
                  <MathJax.Node>y' = w_1 x_1 + b</MathJax.Node>
                </div>
              </MathJax.Context>
            </div>
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              And we can define each variable as follows:
            </p>
            <div
              className={
                this.props.theme.enableDarkMode
                  ? classes.unorderedListDark
                  : classes.unorderedListLight
              }
            >
              <ul>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>y'</MathJax.Node>
                      </b>
                      {'  '}is called a{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.definitionTextDark
                            : classes.definitionTextLight
                        }
                        onClick={() =>
                          this.setTerminologyText(
                              'Label',
                              'A feature is an input variable—the x variable in simple linear regression.'
                          )
                        }
                      >
                        label
                      </b>
                      . This is the value we are trying to predict.
                    </div>
                  </MathJax.Context>
                </li>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>w_1</MathJax.Node>
                      </b>
                      {'  '}is the weight vector of feature 1. Think of weight
                      as the slope{' '}
                      <MathJax.Context input="ascii">
                        <MathJax.Node>(m)</MathJax.Node>
                      </MathJax.Context>{' '}
                      for a line equation.
                    </div>
                  </MathJax.Context>
                </li>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>x_1</MathJax.Node>
                      </b>
                      {'  '}is called a{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.definitionTextDark
                            : classes.definitionTextLight
                        }
                        onClick={() =>
                          this.setTerminologyText(
                              'Feature',
                              'An input variable used in making predictions.'
                          )
                        }
                      >
                        feature
                      </b>
                      . These are just the input variables and more complex
                      examples can include multiple features.
                    </div>
                  </MathJax.Context>
                </li>
                <li>
                  <MathJax.Context input="ascii">
                    <div>
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Node>b</MathJax.Node>
                      </b>
                      {'  '}is called the{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.definitionTextDark
                            : classes.definitionTextLight
                        }
                        onClick={() =>
                          this.setTerminologyText(
                              'Bias',
                              'An intercept or offset from an origin.'
                          )
                        }
                      >
                        bias
                      </b>
                      . This is the y-intercept and sometimes might be written
                      as{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Context input="ascii">
                          <MathJax.Node>w_0</MathJax.Node>
                        </MathJax.Context>
                      </b>
                    </div>
                  </MathJax.Context>
                </li>
              </ul>
            </div>

            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.bodyDark
                  : classes.bodyLight
              }
            >
              Well, this concludes the lesson on{' '}
              <b
                className={
                  this.props.theme.enableDarkMode
                    ? classes.definitionTextDark
                    : classes.definitionTextLight
                }
                onClick={() =>
                  this.setTerminologyText(
                      'Linear Regression',
                      'A type of model that outputs a continuous value from a linear combination of input features.'
                  )
                }
              >
                linear regression
              </b>
              . We got a quick refresher on line fitting and learned some key
              terminology that will help us lay the groundwork for our
              foundational understanding of machine learning. In the next post
              I'll describe{' '}
              <b
                className={
                  this.props.theme.enableDarkMode
                    ? classes.definitionTextDark
                    : classes.definitionTextLight
                }
                onClick={() =>
                  this.setTerminologyText(
                      'Loss',
                      'A measure of how bad a model is. To obtain this value, a model must first have a loss function.'
                  )
                }
              >
                loss
              </b>{' '}
              and how it relates to making predictions.
            </p>
          </div>
        </div>
        <Footer mode={this.props} />
      </div>
    );
  }
}

Regression.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonPostStyles,
    darkPostStyles,
    dialogStylesDark,
    dialogStylesLight,
    lightPostStyles,
);

export default withStyles(combinedStyles)(Regression);
