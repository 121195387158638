import {fonts, colors} from '../../theme';

const styles = (theme) => ({
  arrowPopper: {
    borderRadius: 10,
    backgroundColor: colors.primary,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 15px 30px rgba(75,85,209,.3)',
    fontSize: 12,
    width: 150,
  },
  bitzRocket: {
    height: 50,
    marginTop: 5,
  },
  bitzRocketLink: {
    display: 'inline-flex',
    textDecoration: 'none',
    flex: 1,
  },
  button: {
    '&:hover': {
      color: '#6168e6',
    },
    'fontFamily': fonts.base,
    'color': colors.primary,
    'textTransform': 'none',
    'margin': theme.spacing.unit,
    'fontSize': 16,
  },
  cancelIcon: {
    color: '#d7ddf1',
    cursor: 'pointer',
  },
  faIcon: {
    fontSize: 22,
  },
  fullWidth: {
    width: '100%',
  },
  menuButtonDark: {
    color: colors.darkPrimary,
  },
  headerItems: {
    flexGrow: 1,
  },
  headerTitle: {
    color: colors.primary,
    marginLeft: 10,
    fontFamily: fonts.base,
    fontWeight: 600,
  },
  headerItemText: {
    'fontFamily': fonts.base,
    'color': colors.primary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuText: {
    lineHeight: 1.5,
    padding: '7, 30',
    fontFamily: fonts.base,
    color: '#FFF',
    fontSize: 14,
  },
  mobilePopperText: {
    'color': '#707cb2',
    'fontFamily': fonts.base,
    'fontSize': 14,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0) !important',
    },
  },
  mobilePopperTitleText: {
    color: '#a6b0d4',
    flex: 1,
    fontFamily: fonts.base,
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: 15,
    margin: '0px !important',
  },
  mobilePopperWrapper: {
    transform: 'none !important',
    position: 'absolute',
    top: 25,
    left: '10px !important',
    right: 10,
    zIndex: 5,
    padding: 20,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  popper: {
    'backgroundColor': colors.primary,
    '-webkit-box-shadow': '0 15px 30px rgba(75,85,209,.3)',
    'box-shadow': '0 15px 30px rgba(75,85,209,.3)',
  },
  popperColumn: {
    paddingTop: '0px !important',
  },
  popperColumnNoPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  popperColumnTitle: {
    paddingBottom: '0px !important',
  },
  popperIcon: {
    'color': '#FFF',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0) !important',
    },
  },
  popperTitleDisabled: {
    '&:hover': {
      backgroundColor: 'transparent !important',
      cursor: 'default !important',
    },
  },
  popperText: {
    color: '#707cb2',
    padding: 8,
    fontFamily: fonts.base,
    fontSize: 14,
    margin: 0,
  },
  root: {
    flexGrow: 1,
  },
  toolbar: {
    height: 80,
  },
});

export default styles;
