import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import mobilePhones from '../../images/mobile_phones.svg';
import androidLogoPurple from '../../images/android_logo_purple.svg';
import androidLogoPurpleDark from '../../images/android_logo_purple_dark.svg';
import androidLogoWhite from '../../images/android_logo_white.svg';
import androidLogoWhiteDark from '../../images/android_logo_white_dark.svg';
import appleLogoPurple from '../../images/apple_logo_purple.svg';
import appleLogoPurpleDark from '../../images/apple_logo_purple_dark.svg';
import appleLogoWhite from '../../images/apple_logo_white.svg';
import appleLogoWhiteDark from '../../images/apple_logo_white_dark.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Stores
 */
class Stores extends React.Component {
  goToHuntClubAndroid = () => {
    window.open(
        'https://play.google.com/store/apps/details?id=com.trophygametrackerllc.huntclubmanager'
    );
  };

  goToRxmazeAndroid = () => {
    window.open(
        'https://play.google.com/store/apps/details?id=com.bitzllc.rxmaze'
    );
  };

  goToRxmazeIos = () => {
    window.open(
        'https://itunes.apple.com/us/app/rxmaze/id1463189893?ls=1&mt=8'
    );
  };

  goToHuntClubIos = () => {
    window.open(
        'https://itunes.apple.com/us/app/hunt-club/id1458829847?ls=1&mt=8'
    );
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.storeBackgroundDark
            : classes.storeBackgroundLight
        }
      >
        <Grid container spacing={24}>
          <Grid item xs={8} sm={6}>
            <div className={classes.storeCard}>
              <h1
                className={
                  mode.theme.enableDarkMode
                    ? classes.storeTitleDark
                    : classes.storeTitleLight
                }
              >
                In Stores
              </h1>
              <p
                className={
                  mode.theme.enableDarkMode
                    ? classes.storeSubTitleDark
                    : classes.storeSubTitleLight
                }
              >
                Download these publicly available apps in the Apple or Google
                Play Store. Don't forget to leave a 5 star review!
              </p>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.huntClubCardDark
                    : classes.huntClubCardLight
                }
              >
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.huntClubTextDark
                      : classes.huntClubTextLight
                  }
                >
                  Hunt Club
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.huntClubDescriptionDark
                      : classes.huntClubDescriptionLight
                  }
                >
                  Connect with hunters to mark your stand and plan your hunt.
                </p>
                <div className={classes.iconContainer}>
                  <img
                    onClick={this.goToHuntClubIos}
                    className={classes.purpleAppleLogo}
                    alt="purple apple logo"
                    src={
                      mode.theme.enableDarkMode
                        ? appleLogoPurpleDark
                        : appleLogoPurple
                    }
                  />
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.borderDark
                        : classes.borderLight
                    }
                  />
                  <img
                    onClick={this.goToHuntClubAndroid}
                    className={classes.purpleAndroidLogo}
                    alt="purple android logo"
                    src={
                      mode.theme.enableDarkMode
                        ? androidLogoPurpleDark
                        : androidLogoPurple
                    }
                  />
                </div>
              </div>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.rxMazeCardDark
                    : classes.rxMazeCardLight
                }
              >
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.rxMazeTextDark
                      : classes.rxMazeTextLight
                  }
                >
                  RXMaze
                </p>
                <p className={classes.rxMazeDescription}>
                  A one stop shop for health care consumers.
                </p>
                <div className={classes.iconContainer}>
                  <img
                    onClick={this.goToRxmazeIos}
                    className={classes.whiteAppleLogo}
                    alt="white apple logo"
                    src={
                      mode.theme.enableDarkMode
                        ? appleLogoWhiteDark
                        : appleLogoWhite
                    }
                  />
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.borderDark
                        : classes.borderLight
                    }
                  />
                  <img
                    onClick={this.goToRxmazeAndroid}
                    className={classes.whiteAndroidLogo}
                    alt="white android logo"
                    src={
                      mode.theme.enableDarkMode
                        ? androidLogoWhiteDark
                        : androidLogoWhite
                    }
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={6}>
            <img
              className={classes.mobileMockups}
              alt="mobile app mockups"
              src={mobilePhones}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Stores.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Stores);
