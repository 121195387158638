import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  processBackgroundLight: {
    backgroundColor: '#F3F7FE',
    paddingBottom: 80,
    paddingTop: 40,
  },
  processTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.primary,
    marginBottom: 0,
    marginLeft: 30,
  },
  processCardTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: colors.primary,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  processCardSubTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#333',
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  processSubTitleLight: {
    marginTop: 13,
    color: '#9198B2',
    fontFamily: fonts.base,
    fontWeight: 600,
    marginBottom: 73,
    maxWidth: 400,
    marginLeft: 30,
  },
  processCardLight: {
    backgroundColor: colors.white,
    borderRadius: 12,
    marginTop: 30,
    padding: 20,
  },
  vectorBackgroundLight: {
    backgroundColor: '#F3F7FE',
    display: 'block',
    margin: 'auto',
    height: 150,
    width: 150,
    borderRadius: 180,
  },
});

export default styles;
