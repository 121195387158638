import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

// Images
import bathCounty from '../../images/bath_county_collision.jpg';
import dans from '../../images/dans_body_service.jpg';
import hbpCare from '../../images/hbp_care.jpg';
import huntClub from '../../images/hunt_club.jpg';
import rmWoodworks from '../../images/rm_woodworks.jpg';
import rxmaze from '../../images/rxmaze.jpg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Projects
 */
class Projects extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  goToDans = () => {
    window.open('https://dansbodyservice.net/');
  };

  goToHuntClub = () => {
    window.open('https://huntclub.io');
  };

  goToHBPCare = () => {
    window.open('https://www.hbpcare.com/');
  };

  goToBathCounty = () => {
    window.open('https://www.bathcountycollision.com/');
  };

  goToRXMaze = () => {
    window.open('https://rxmaze.com/');
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render = () => {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.projectsBackgroundDark
            : classes.projectsBackgroundLight
        }
      >
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <p className={classes.dialogTitle}>Site No Longer Available</p>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogDescription}
            >
              Sorry, this e-commerce site was closed by the client.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              className={classes.dialogButtonText}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <h1
              className={
                mode.theme.enableDarkMode
                  ? classes.serviceTitleDark
                  : classes.serviceTitleLight
              }
            >
              Projects
            </h1>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div>
              <div className={classes.huntClubCard}>
                <img
                  onClick={this.goToRXMaze}
                  className={classes.projectImage}
                  alt="doctor and pharmacy"
                  src={rxmaze}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectTitleDark
                      : classes.projectTitleLight
                  }
                >
                  RXMaze
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectDescriptionDark
                      : classes.projectDescriptionLight
                  }
                >
                  Pharmaceutical startup, building a platform enabling users to
                  fufill prescriptions, get drug info, and get medical help.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div className={classes.storeCard}>
              <div className={classes.huntClubCard}>
                <img
                  onClick={this.goToDans}
                  className={classes.projectImage}
                  alt="spray gun spraying paint"
                  src={dans}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectTitleDark
                      : classes.projectTitleLight
                  }
                >
                  Dan's Auto Body
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectDescriptionDark
                      : classes.projectDescriptionLight
                  }
                >
                  Dan's has affordable and reliable auto paint and collision
                  repair services that turn the car you drive back into the car
                  you love.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div className={classes.storeCard}>
              <div className={classes.huntClubCard}>
                <img
                  onClick={this.goToHuntClub}
                  className={classes.projectImage}
                  alt="large buck in field"
                  src={huntClub}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectTitleDark
                      : classes.projectTitleLight
                  }
                >
                  Hunt Club
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectDescriptionDark
                      : classes.projectDescriptionLight
                  }
                >
                  A tech startup currently showcasing their upcoming new app
                  Hunt Club. A social media application for hunters and hunting
                  clubs.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div className={classes.storeCard}>
              <div className={classes.huntClubCard}>
                <img
                  onClick={this.goToHBPCare}
                  className={classes.projectImage}
                  alt="stuffed animals in a basket"
                  src={hbpCare}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectTitleDark
                      : classes.projectTitleLight
                  }
                >
                  HBP Care
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectDescriptionDark
                      : classes.projectDescriptionLight
                  }
                >
                  Home, Baby, and Pet Care offers a bit of everything at
                  affordable prices. Think of it as a smaller version of Amazon.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div className={classes.storeCard}>
              <div className={classes.huntClubCard}>
                <img
                  onClick={this.goToBathCounty}
                  className={classes.projectImage}
                  alt="white car being repaired in service center"
                  src={bathCounty}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectTitleDark
                      : classes.projectTitleLight
                  }
                >
                  Bath County Collision
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectDescriptionDark
                      : classes.projectDescriptionLight
                  }
                >
                  Home, Baby, and Pet Care offers a bit of everything at
                  affordable prices. Think of it as a smaller version of Amazon.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div className={classes.storeCard}>
              <div className={classes.huntClubCard}>
                <img
                  onClick={this.handleClickOpen}
                  className={classes.projectImage}
                  alt="woodsmith working on work bench"
                  src={rmWoodworks}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectTitleDark
                      : classes.projectTitleLight
                  }
                >
                  RM-Woodworks
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.projectDescriptionDark
                      : classes.projectDescriptionLight
                  }
                >
                  Looking for beautiful hand-made furniture? How about custom
                  photography? Look no further, this ecommerce site offers just
                  that.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
}

Projects.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Projects);
