/* eslint-disable max-len */
import {fonts, colors} from '../../../theme';
import backgroundPrimary from '../../../images/lines_circles.svg';

const styles = (theme) => ({
  backgroundLight: {
    backgroundColor: colors.white,
    paddingBottom: 100,
  },
  backgroundHeroLight: {
    backgroundColor: colors.white,
  },
  bodyLight: {
    textAlign: 'left',
    color: '#707CB2',
    fontSize: 18,
    marginTop: 30,
    marginBottom: 0,
    fontFamily: fonts.base,
    marginLeft: 100,
    marginRight: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
      marginRight: 25,
    },
  },
  bulletLight: {
    textAlign: 'left',
    color: '#707CB2',
    fontSize: 18,
    marginTop: 10,
    marginBottom: 5,
    fontFamily: fonts.base,
    marginLeft: 150,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 50,
    },
  },
  cardImageAlt: {
    'backgroundImage': `url(${backgroundPrimary})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundColor': '#38D39F',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 800,
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    'display': 'block',
    'margin': 'auto',
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
    'margin-bottom': 20,
  },
  cardImagePrimary: {
    'backgroundImage': `url(${backgroundPrimary})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundColor': '#6067F1',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 800,
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    'display': 'block',
    'margin': 'auto',
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
    'margin-bottom': 20,
  },
  definitionTextLight: {
    'color': colors.primary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  figureTextLight: {
    color: '#5F6A96',
    fontFamily: fonts.base,
    fontWeight: 600,
  },
  formulaLight: {
    marginTop: 30,
    fontStyle: 'italic',
    color: '#707CB2',
    textAlign: 'center',
  },
  formulaTextLight: {
    color: '#707CB2',
  },
  linkLight: {
    color: '#6067f1',
    textDecoration: 'none',
  },
  secondaryHeaderLight: {
    textAlign: 'left',
    color: '#5F6A96',
    fontSize: 36,
    marginTop: 50,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
    },
  },
  titleLight: {
    textAlign: 'left',
    color: '#5F6A96',
    fontSize: 36,
    marginTop: 0,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
    },
  },
  unorderedListLight: {
    textAlign: 'left',
    color: '#707CB2',
    backgroundColor: colors.lightThemeBlueBackground,
    padding: 20,
    borderRadius: 8,
    fontSize: 18,
    marginTop: 30,
    marginBottom: 0,
    fontFamily: fonts.base,
    marginLeft: 100,
    marginRight: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
      marginRight: 25,
    },
  },
});

export default styles;
