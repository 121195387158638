const styles = (theme) => ({
  heroVector: {
    margin: 'auto',
    display: 'block',
    width: '100%',
  },
  heroVectorMobile: {
    margin: 'auto',
    display: 'block',
    width: '85%',
  },
  heroImage: {
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: 900,
    },
    [theme.breakpoints.down('lg')]: {
      width: 800,
    },
    [theme.breakpoints.down('md')]: {
      width: 700,
    },
    [theme.breakpoints.down('sm')]: {
      width: 600,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
});

export default styles;
