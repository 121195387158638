import React from 'react';
import {render} from 'react-snapshot';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
const hist = createBrowserHistory();

render(
    <Router history={hist}>
      <App />
    </Router>,
    document.getElementById('root')
);
registerServiceWorker();
