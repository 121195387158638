import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  rootDark: {
    paddingTop: 16,
    paddingBottom: 16,
    flexGrow: 1,
    backgroundColor: colors.darkThemeMediumGreyBackground,
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
      textAlign: 'center',
    },
  },
  linkDark: {
    'textDecoration': 'none',
    'fontFamily': fonts.base,
    'fontSize': 14,
    'color': colors.darkThemeWhiteText,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bitzTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 25,
    color: colors.darkPrimary,
    width: 70,
  },
  bitzSloganDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#A6B0D4',
    marginTop: 10,
    marginBottom: 0,
  },
  bitzMessageDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#A6B0D4',
    marginTop: 0,
  },
  linkTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#A6B0D4',
  },
  heartIconDark: {
    '&:hover': {
      backgroundColor: '#F3F7FE !important',
      cursor: 'default',
    },
    'color': 'rgba(245, 95, 68, .5)',
    'marginBottom': 5,
    'width': 30,
  },
});

export default styles;
