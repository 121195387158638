import {fonts, colors} from '../../../theme';

const styles = (theme) => ({
  dialogLight: {
    backgroundColor: colors.white,
  },
  dialogFooterLight: {
    backgroundColor: colors.white,
    margin: 0,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
  },
  dialogTitleLight: {
    margin: 0,
    fontFamily: 'Poppins !important',
    fontWeight: 600,
    color: colors.primary,
  },
  dialogDescriptionLight: {
    fontFamily: fonts.base,
    color: colors.lightThemeBlack,
  },
  dialogButtonTextLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
  },
});

export default styles;
