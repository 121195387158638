const styles = (theme) => ({
  textContainer: {
    textAlign: 'center',
    padding: 50,
    [theme.breakpoints.down('lg')]: {
      padding: 50,
    },
    [theme.breakpoints.down('md')]: {
      padding: 50,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 50,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  bitzRocket: {
    width: 40,
    marginTop: 50,
  },
  noUnderline: {
    textDecoration: 'none',
  },
});

export default styles;
