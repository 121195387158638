import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  bodyBackgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    paddingBottom: 100,
  },
  titleDark: {
    textAlign: 'left',
    color: colors.darkPrimary,
    fontSize: 36,
    marginTop: 0,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
    },
  },
  secondaryHeaderDark: {
    textAlign: 'left',
    color: colors.darkPrimary,
    fontSize: 36,
    marginTop: 50,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
    },
  },
  bodyDark: {
    textAlign: 'left',
    color: colors.darkThemeWhiteText,
    fontSize: 18,
    marginTop: 30,
    marginBottom: 0,
    fontFamily: fonts.base,
    marginLeft: 100,
    marginRight: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
      marginRight: 25,
    },
  },
  bulletDark: {
    textAlign: 'left',
    color: colors.darkThemeWhiteText,
    fontSize: 18,
    marginTop: 10,
    marginBottom: 5,
    fontFamily: fonts.base,
    marginLeft: 150,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 50,
    },
  },
});

export default styles;
