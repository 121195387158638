import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  heroBackgroundDark: {
    backgroundColor: colors.darkThemeMediumGreyBackground,
    paddingTop: 70,
    paddingBottom: 70,
  },
  contactDark: {
    'backgroundColor': colors.darkPrimary,
    'boxShadow': 'none',
    'display': 'block',
    'marginTop': 30,
    'height': 45,
    'width': 120,
    'borderRadius': 30,
    '&:hover': {
      backgroundColor: colors.darkPrimary,
    },
  },
  heroHeadlineDark: {
    marginTop: 0,
    maxWidth: 460,
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
  },
  heroHeadlineMobileDark: {
    marginTop: 0,
    fontSize: 34,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
    marginLeft: 30,
  },
  heroMissionDark: {
    marginTop: 30,
    maxWidth: 460,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
  },
  heroMissionMobileDark: {
    marginTop: 30,
    maxWidth: 350,
    marginLeft: 30,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#E1E1E4',
  },
});

export default styles;
