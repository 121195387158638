import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import musicLight from '../../images/music_light.svg';
import musicDark from '../../images/music_dark.svg';
import fitnessLight from '../../images/fitness_light.svg';
import fitnessDark from '../../images/fitness_dark.svg';
import sportsLight from '../../images/sports_light.svg';
import sportsDark from '../../images/sports_dark.svg';
import readingLight from '../../images/reading_light.svg';
import readingDark from '../../images/reading_dark.svg';
import moviesLight from '../../images/movies_light.svg';
import moviesDark from '../../images/movies_dark.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Process
 */
class Process extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.processBackgroundDark
            : classes.processBackgroundLight
        }
      >
        <Grid container className={classes.processContainer} spacing={24}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.noPaddingBottom}
          >
            <Grid item xs={12} sm={12} md={12}>
              <h1
                className={
                  mode.theme.enableDarkMode
                    ? classes.processTitleDark
                    : classes.processTitleLight
                }
              >
                Interests
              </h1>
              <p
                className={
                  mode.theme.enableDarkMode
                    ? classes.processSubTitleDark
                    : classes.processSubTitleLight
                }
              >
                If I’m not coding or doing one of the following it’s most
                likely my doppleganger…
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.vectorImage}
                        alt="individual working out (healthy habits)"
                        src={
                          mode.theme.enableDarkMode
                            ? fitnessDark
                            : fitnessLight
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Fitness
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      Exercise not only changes your body, but it changes
                      your mind, your attitude, and your mood.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.vectorImage}
                        alt="individual sitting on books"
                        src={
                          mode.theme.enableDarkMode
                            ? readingDark
                            : readingLight
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Reading
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      I’m typically pursuing multiple books at any given
                      time.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            className={classes.noPaddingTop}
          >
            <div
              className={
                this.props.width === 'xs'
                  ? null
                  : mode.theme.enableDarkMode
                  ? classes.processCardDark
                  : classes.processCardLight
              }
            >
              <Grid
                container
                className={classes.processContainer}
                spacing={24}
              >
                <Grid item xs={12} sm={5} md={4}>
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.vectorBackgroundDark
                        : classes.vectorBackgroundLight
                    }
                  >
                    <img
                      className={classes.vectorImage}
                      alt="individuals listening to music"
                      src={
                        mode.theme.enableDarkMode ? musicDark : musicLight
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} md={8}>
                  <h1
                    className={
                      mode.theme.enableDarkMode
                        ? classes.processCardTitleDark
                        : classes.processCardTitleLight
                    }
                  >
                    Music
                  </h1>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.processCardSubTitleDark
                        : classes.processCardSubTitleLight
                    }
                  >
                    I spend large portion of my day looking at screen and it
                    couldn’t be done without some jams.
                  </p>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12} sm={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.vectorImage}
                        alt="goalie standing in front of net"
                        src={
                          mode.theme.enableDarkMode
                            ? sportsDark
                            : sportsLight
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Sports
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      After college soccer, I’ve really been getting into
                      the life-long sports (golf).
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.vectorImage}
                        alt="star wars vador vs. luke"
                        src={
                          mode.theme.enableDarkMode
                            ? moviesDark
                            : moviesLight
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Movies
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      Usually any sci-fi or mind-bending flim peaks my
                      interest.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Process.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Process);
