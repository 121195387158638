import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../components/error/error';
import {Helmet} from 'react-helmet';

/**
 * Error
 */
class Error extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>404 - Bitz LLC</title>
          <meta
            name="description"
            content="Oops! Page not found. Go back home."
          />
          <meta
            name="keywords"
            content="error, not found, 404, oops"
          />
        </Helmet>
        <NotFound />
      </div>
    );
  }
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Error;
