const styles = (theme) => ({
  container: {
    padding: 40,
  },
  goDukes: {
    marginTop: 50,
    display: 'block',
    margin: 'auto',
  },
  undergradImage: {
    width: '100%',
  },
});

export default styles;
