import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  experienceBackgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    paddingBottom: 50,
    paddingTop: 50,
  },
  descriptionDark: {
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
  },
  gridWrapperDark: {
    width: '85%',
    margin: '0px auto 0',
    marginTop: 40,
    paddingBottom: 40,
    position: 'relative',
    borderRadius: 8,
    backgroundColor: colors.darkThemeLightGreyBackground,
  },
  headerDark: {
    textAlign: 'center',
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.darkPrimary,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  imagePanelDark: {
    marginTop: 50,
    marginLeft: 50,
    margin: 'auto !important',
    display: 'block !important',
    boxShadow: '0 0 35px rgba(0,0,0,.5)',
    backgroundColor: colors.darkThemeLightGreyBackground,
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      width: 225,
    },
    [theme.breakpoints.down('x')]: {
      marginTop: 30,
    },
    width: 300,
  },
  titleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
  },
  unorderedListDark: {
    color: colors.darkThemeWhiteText,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    marginLeft: 0,
    paddingLeft: 22,
    fontFamily: fonts.base,
  },
});

export default styles;
