import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  mobileCardLight: {
    backgroundColor: '#F3F7FE',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 20,
    borderRadius: 20,
  },
  mobileTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.primary,
    marginBottom: 50,
    position: 'relative',
    zIndex: 1000,
  },
  mobileTextLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: colors.primary,
  },
  mobileDescriptionLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#333',
    fontSize: 14,
    marginBottom: 60,
    maxWidth: 400,
  },
  reactReduxLogoLight: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
  },
  webCardLight: {
    backgroundColor: colors.primary,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 20,
    borderRadius: 20,
  },
  webTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: '#FFFFFF',
    marginBottom: 50,
    position: 'relative',
    zIndex: 1000,
  },
  webTextLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: '#FFFFFF',
  },
  webDescriptionLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: 'rgba(255, 255, 255, .5)',
    marginBottom: 60,
    maxWidth: 400,
  },
});

export default styles;
