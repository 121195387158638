const data = [
  {
    x: 559,
    y: 213,
  },
  {
    x: 562,
    y: 216,
  },
  {
    x: 564,
    y: 207,
  },
  {
    x: 717,
    y: 231,
  },
  {
    x: 620,
    y: 233,
  },
  {
    x: 672,
    y: 231,
  },
  {
    x: 609,
    y: 241,
  },
  {
    x: 629,
    y: 222,
  },
  {
    x: 645,
    y: 247,
  },
  {
    x: 606,
    y: 226,
  },
  {
    x: 660,
    y: 240,
  },
  {
    x: 630,
    y: 215,
  },

  {
    x: 778,
    y: 263,
  },
  {
    x: 616,
    y: 220,
  },
  {
    x: 727,
    y: 271,
  },
  {
    x: 778,
    y: 279,
  },
  {
    x: 755,
    y: 268,
  },
  {
    x: 710,
    y: 278,
  },
  {
    x: 701,
    y: 238,
  },
  {
    x: 635,
    y: 236,
  },
  {
    x: 565,
    y: 204,
  },
  {
    x: 580,
    y: 225,
  },
  {
    x: 596,
    y: 220,
  },
  {
    x: 597,
    y: 219,
  },
  {
    x: 636,
    y: 201,
  },
  {
    x: 615,
    y: 228,
  },
  {
    x: 740,
    y: 234,
  },
  {
    x: 677,
    y: 237,
  },
  {
    x: 675,
    y: 217,
  },
  {
    x: 629,
    y: 211,
  },
  {
    x: 692,
    y: 238,
  },
  {
    x: 710,
    y: 221,
  },
  {
    x: 730,
    y: 281,
  },
  {
    x: 763,
    y: 292,
  },
  {
    x: 686,
    y: 251,
  },

  {
    x: 737,
    y: 275,
  },
];

export default data;
