/* eslint-disable max-len */
import React from 'react';
import NavBar from '../components/app_bar/appbar';
import QuestionAnswer from '../components/faq/qa';
import Hero from '../components/hero/faq/hero';
import Footer from '../components/footer/about/footer';

/**
 * Faq
 */
class Faq extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const questions = [
      {
        question: 'Can you build me a website?',
        answer: 'I’d be honored to do your next website, but please include as much detail in regards to what you are looking for.',
      },
      {
        question: 'How long will the project take?',
        answer: 'The real answer is, it depends. Based on you’re needs it will be easier to determine a timeframe for the effort.',
      },
      {
        question: 'Can you update to my website?',
        answer: 'Absolutely, if you already have  a website, but just need a few small tweaks, I can go in and fufill your requirement.',
      },
      {
        question: 'Can you help me migrate hosting services?',
        answer: 'Of course. If your domain is hosted on GoDaddy and you want  to move to something like AWS, I would be happy to assist.',
      },
    ];
    return (
      <div>
        <NavBar mode={this.props} />
        <Hero mode={this.props} />
        <QuestionAnswer mode={this.props} questions={questions} />
        <Footer mode={this.props} />
      </div>
    );
  }
}

export default Faq;
