import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  flexDark: {
    flexGrow: 1,
    backgroundColor: colors.darkThemeDarkGreyBackground,
  },
  mobileCardDark: {
    backgroundColor: colors.darkThemeMediumGreyBackground,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 20,
    borderRadius: 20,
  },
  mobileTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.darkPrimary,
    marginBottom: 50,
    position: 'relative',
    zIndex: 1000,
  },
  mobileTextDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: colors.darkPrimary,
  },
  mobileDescriptionDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
    fontSize: 14,
    marginBottom: 60,
    maxWidth: 400,
  },
  reactReduxLogoDark: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 40,
      top: 40,
      opacity: 0.2,
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: 40,
      top: 40,
      opacity: 1,
    },
  },
  webCardDark: {
    backgroundColor: colors.darkThemeLightGreyBackground,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 20,
    borderRadius: 20,
  },
  webTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.darkThemeWhiteText,
    marginBottom: 50,
    position: 'relative',
    zIndex: 1000,
  },
  webTextDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: colors.darkThemeWhiteText,
  },
  webDescriptionDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: colors.lightThemeWhite,
    marginBottom: 60,
    maxWidth: 400,
  },
});

export default styles;
