/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Footer from '../../footer/about/footer';
import Header from '../../posts/header/header';
import NavBar from '../../app_bar/appbar';
import {NavLink} from 'react-router-dom';

// Theming
import combineStyles from '../../../utilities/combine_styles';
import {withStyles} from '@material-ui/core/styles';
import commonPostStyles from '../../../theme/posts/common/styles';
import darkPostStyles from '../../../theme/posts/dark/styles';
import lightPostStyles from '../../../theme/posts/light/styles';
import dialogStylesDark from '../../../theme/dialogs/dark/styles';
import dialogStylesLight from '../../../theme/dialogs/light/styles';

// Images
import logoDark from '../../../images/bitz_logo_green.svg';
import logoLight from '../../../images/bitz_logo_alt.svg';
import lowLossDark from '../../../images/low_loss_dark.svg';
import lowLossLight from '../../../images/low_loss_light.svg';
import highLossDark from '../../../images/high_loss_dark.svg';
import highLossLight from '../../../images/high_loss_light.svg';

// Visualization
import MathJax from 'react-mathjax2';

// Header info
const date = 'June 17, 2019';
const type = 'Developer';
const title = 'Loss and Predictions';
const author = 'Brandon Fitzwater';
// eslint-disable-next-line no-useless-escape
const meanSquareError = 'MSE = 1/Nsum_{(x,y)inD} (y - prediction(x))^2';

/**
 * Loss
 */
class Loss extends React.Component {
  state = {
    height: 0,
    width: 0,
    open: false,
    dialogTitle: '',
    dialogBody: '',
  };

  /**
   * Lifecyle method
   */
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * Lifecyle method
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * @Function handleClose
   * @Purpose closes terminology dialog
   */
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  /**
   * @Function updateWindowDimensions
   * @Purpose updates width and height of the window
   */
  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  /**
   * @Function openDialog
   * @Purpose opens generic dialog
   * @param {String} title title of dialog
   * @param {String} body message to display in body
   */
  setTerminologyText = (title, body) => {
    this.setState({
      open: true,
      dialogTitle: title,
      dialogBody: body,
    });
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogDark
                : classes.dialogLight
            }
          >
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogTitleDark
                  : classes.dialogTitleLight
              }
            >
              {this.state.dialogTitle}
            </p>
          </DialogTitle>
          <DialogContent
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogDark
                : classes.dialogLight
            }
          >
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogDescriptionDark
                  : classes.dialogDescriptionLight
              }
            >
              {this.state.dialogBody}
            </p>
          </DialogContent>
          <DialogActions
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogFooterDark
                : classes.dialogFooterLight
            }
          >
            <Button
              onClick={this.handleClose}
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogButtonTextDark
                  : classes.dialogButtonTextLight
              }
              autoFocus
            >
              Got it
            </Button>
          </DialogActions>
        </Dialog>
        <NavBar mode={this.props} />
        <Header
          date={date}
          type={type}
          title={title}
          author={author}
          mode={this.props}
        />
        <Grid
          container
          spacing={24}
          className={
            this.props.theme.enableDarkMode
              ? classes.backgroundHeroDark
              : classes.backgroundHeroLight
          }
        >
          <Grid item xs={12}>
            <div>
              <div className={classes.card}>
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.cardImageDarkGrey
                      : classes.cardImageAlt
                  }
                >
                  <img
                    className={classes.bitzRocket}
                    alt="Bitz LLC rocket logo"
                    src={
                      this.props.theme.enableDarkMode ? logoDark : logoLight
                    }
                  />
                  <p className={classes.headline}>Machine Learning</p>
                  <p
                    className={
                      this.props.theme.enableDarkMode
                        ? classes.titleGreen
                        : classes.title
                    }
                  >
                    Loss and Predictions
                  </p>
                  <p className={classes.shortDescription}>
                    Squared Loss and Mean Square Error
                  </p>
                </div>
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.backgroundDark
                      : classes.backgroundLight
                  }
                >
                  <div>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.titleDark
                          : classes.titleLight
                      }
                    >
                      Introduction
                    </p>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.bodyDark
                          : classes.bodyLight
                      }
                    >
                      Suppose you're{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.definitionTextDark
                            : classes.definitionTextLight
                        }
                        onClick={() =>
                          this.setTerminologyText(
                              'Training',
                              'The process of determining the ideal parameters comprising a model.'
                          )
                        }
                      >
                        training
                      </b>{' '}
                      a model and you're trying to determine if you've fit a
                      good line for all the weight vectors and bias from
                      labeled examples. Remember these labels can be just
                      about anything, from the future price of a stock or
                      whether an email is spam and recall that our objective
                      when training is to find a model that best fits our
                      dataset. So what is really going on when we find a
                      line of best fit? What's happening is you are
                      minimizing loss. But what is loss you're thinking?
                    </p>
                  </div>
                  <div>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.secondaryHeaderDark
                          : classes.secondaryHeaderLight
                      }
                    >
                      Loss
                    </p>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.bodyDark
                          : classes.bodyLight
                      }
                    >
                      Simply put,{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.definitionTextDark
                            : classes.definitionTextLight
                        }
                        onClick={() =>
                          this.setTerminologyText(
                              'Loss',
                              'A measure of how bad a model is. To obtain this value, a model must first have a loss function.'
                          )
                        }
                      >
                        loss
                      </b>{' '}
                      is a measure of how bad a model is and comes from a
                      poor prediction made on a single example. We give loss
                      a numeric value from taking the difference between the
                      prediction and the actual value for any given example.
                      Loss is always on a zero to positive scale and as you
                      can guess a perfect model has a loss of zero while an
                      extremely poor model has a very high loss. Let's take
                      a look at two examples to see the difference between
                      high and low loss.
                    </p>
                  </div>
                  <div
                    className={
                      this.props.theme.enableDarkMode
                        ? classes.unorderedListDark
                        : classes.unorderedListLight
                    }
                  >
                    <ul>
                      <li>The dotted lines represent loss</li>
                      <li>The solid blue line represents predictions</li>
                    </ul>
                  </div>
                  <Grid
                    container
                    spacing={24}
                    className={
                      this.props.theme.enableDarkMode
                        ? classes.gridWrapperDark
                        : classes.gridWrapperLight
                    }
                  >
                    <Grid item xs={12} sm={6}>
                      <div className={classes.chartContainer}>
                        <img
                          alt="machine learning model with low loss"
                          src={
                            this.props.theme.enableDarkMode
                              ? lowLossDark
                              : lowLossLight
                          }
                        />
                        <p
                          className={
                            this.props.theme.enableDarkMode
                              ? classes.figureTextDark
                              : classes.figureTextLight
                          }
                        >
                          Figure 1. The model above shows low loss.
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className={classes.chartContainer}>
                        <img
                          alt="machine learning model with high loss"
                          src={
                            this.props.theme.enableDarkMode
                              ? highLossDark
                              : highLossLight
                          }
                        />
                        <p
                          className={
                            this.props.theme.enableDarkMode
                              ? classes.figureTextDark
                              : classes.figureTextLight
                          }
                        >
                          Figure 2. The model above shows high loss.
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <div>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.bodyDark
                          : classes.bodyLight
                      }
                    >
                      Just by doing a simple eye test, it is clear which
                      plot would serve as a better predictive model. That's
                      right, Figure 1 has the lower loss on average across
                      all the examples which is exactly the goal of training
                      a model. As mentioned above loss is the penalty for a
                      bad prediction, which might be leading you to
                      speculate if there is a way to calculate the loss for
                      all the examples. Indeed there is and in machine
                      learning there is a notion of a loss function which
                      totals the indivdual losses across a dataset.
                    </p>
                  </div>
                  <div>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.secondaryHeaderDark
                          : classes.secondaryHeaderLight
                      }
                    >
                      Squared Loss
                    </p>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.bodyDark
                          : classes.bodyLight
                      }
                    >
                      In linear regression, one popular loss function you
                      might see out in the wild is called{' '}
                      <b
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.definitionTextDark
                            : classes.definitionTextLight
                        }
                        onClick={() =>
                          this.setTerminologyText(
                              'Squared Loss',
                              'The loss function used in linear regression. (Also known as L2 Loss.) This function calculates the squares of the difference between a model\'s predicted value for a labeled example and the actual value of the label. Due to squaring, this loss function amplifies the influence of bad predictions. That is, squared loss reacts more strongly to outliers than L1 loss.',
                          )
                        }
                      >
                        squared loss
                      </b>
                      {' '}. This is also known as{' '}
                      <MathJax.Context input="ascii">
                        <MathJax.Node>L_2</MathJax.Node>
                      </MathJax.Context>{' '}
                      loss and it is calcuated for a single example in the
                      following way:
                    </p>
                    <div
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.unorderedListDark
                          : classes.unorderedListLight
                      }
                    >
                      <MathJax.Context input="ascii">
                        <div>
                          ={' '}
                          <MathJax.Node>
                            "the square of the difference between the label
                            and the prediction"
                          </MathJax.Node>
                        </div>
                      </MathJax.Context>
                      <MathJax.Context input="ascii">
                        <div>
                          ={' '}
                          <MathJax.Node>
                            (observation - prediction(x))^2
                          </MathJax.Node>
                        </div>
                      </MathJax.Context>
                      <MathJax.Context input="ascii">
                        <div>
                          ={' '}
                          <MathJax.Node>(y - prediction(x))^2</MathJax.Node>
                        </div>
                      </MathJax.Context>
                    </div>
                    <div>
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        To get the average loss the entire dataset we sum up
                        all the squared losses for each example and then
                        divide by the total number of examples. This
                        technique is called Mean Square Error (MSE) and
                        isn't the best or only loss function out there. This
                        common function can be represented by the follow
                        formula:
                      </p>
                    </div>
                    <div
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.formulaDark
                          : classes.formulaLight
                      }
                    >
                      <MathJax.Context input="ascii">
                        <div
                          className={
                            this.props.theme.enableDarkMode
                              ? classes.formulaDark
                              : classes.formulaLight
                          }
                        >
                          <MathJax.Node>{meanSquareError}</MathJax.Node>
                        </div>
                      </MathJax.Context>
                    </div>
                    <div>
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        Where each variable is defined as follows:
                      </p>
                    </div>
                    <div
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.unorderedListDark
                          : classes.unorderedListLight
                      }
                    >
                      <li>
                        <MathJax.Context input="ascii">
                          <MathJax.Node>N</MathJax.Node>
                        </MathJax.Context>{' '}
                        is the total number of examples in our dataset{' '}
                        <MathJax.Context input="ascii">
                          <MathJax.Node>(D)</MathJax.Node>
                        </MathJax.Context>
                      </li>
                      <li>
                        <MathJax.Context input="ascii">
                          <MathJax.Node>(x, y)</MathJax.Node>
                        </MathJax.Context>{' '}
                        is an example where:
                        <ul className={classes.noMargin}>
                          <li>
                            {' '}
                            <MathJax.Context input="ascii">
                              <MathJax.Node>x</MathJax.Node>
                            </MathJax.Context>{' '}
                            is the set of features (ex.{' '}
                            <NavLink
                              to={'/blog/linear-regression'}
                              className={
                                this.props.theme.enableDarkMode
                                  ? classes.internalLinkDark
                                  : classes.internalLinkLight
                              }
                            >
                              nasal length of a kangaroo
                            </NavLink>
                            ) that the model uses to make predictions.
                          </li>
                          <li>
                            {' '}
                            <MathJax.Context input="ascii">
                              <MathJax.Node>y</MathJax.Node>
                            </MathJax.Context>{' '}
                            is the example's label (ex.{' '}
                            <NavLink
                              to={'/blog/linear-regression'}
                              className={
                                this.props.theme.enableDarkMode
                                  ? classes.internalLinkDark
                                  : classes.internalLinkLight
                              }
                            >
                              nasal width of a kangaroo
                            </NavLink>
                            ).
                          </li>
                        </ul>
                      </li>
                      <li>
                        <MathJax.Context input="ascii">
                          <MathJax.Node>D</MathJax.Node>
                        </MathJax.Context>{' '}
                        is our dataset containing labeled examples,
                        represented in{' '}
                        <MathJax.Context input="ascii">
                          <MathJax.Node>(x, y)</MathJax.Node>
                        </MathJax.Context>{' '}
                        pairs
                      </li>
                      <li>
                        <MathJax.Context input="ascii">
                          <MathJax.Node>prediction(x)</MathJax.Node>
                        </MathJax.Context>{' '}
                        is a function of the bias and weights in conjuction
                        with the set of features.
                      </li>
                    </div>
                    <div>
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        Now that you've got your feet wet with the idea of
                        loss, in the next set of blog posts we'll examine
                        different approaches to reducing loss.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Footer mode={this.props} />
      </div>
    );
  }
}

Loss.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonPostStyles,
    darkPostStyles,
    dialogStylesDark,
    dialogStylesLight,
    lightPostStyles
);

export default withStyles(combinedStyles)(Loss);
