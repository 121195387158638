/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import {NavLink} from 'react-router-dom';

// Images
import logo from '../../../images/bitz_logo_light.svg';
import logoDark from '../../../images/bitz_logo_purple.svg';
import spaceships from '../../../images/spaceships.svg';
import spaceshipsDark from '../../../images/spaceships_dark_alt.svg';

// Styles
import combineStyles from '../../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Footer
 */
class Footer extends React.Component {
  state = {
    open: false,
  };

  sendEmail = () => {
    window.location.href =
      'mailto:brandonfitzh2o@gmail.com?subject=Bitz LLC Inquiry';
  };

  goToGithub = () => {
    window.open('https://github.com/iM-GeeKy');
  };

  goToTwitter = () => {
    window.open('https://twitter.com/im_geeky');
  };

  goToLinkedIn = () => {
    window.open('https://www.linkedin.com/in/brandon-fitzwater-28736b9a/');
  };

  goToFacebook = () => {
    window.open('https://www.facebook.com/brandon.fitzwater.104');
  };

  goToAngelList = () => {
    window.open('https://angel.co/brandon-fitzwater');
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <Paper
        className={
          mode.theme.enableDarkMode ? classes.rootDark : classes.rootLight
        }
        elevation={0}
      >
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <p className={classes.dialogTitle}>Coming Soon</p>
          </DialogTitle>
          <DialogContent className={classes.dialogDescription}>
            This page is currently being developed. Please check back at a later
            time.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              className={classes.dialogButtonText}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <img
              className={classes.spaceships}
              alt="spaceships and aliens"
              src={mode.theme.enableDarkMode ? spaceshipsDark : spaceships}
            />
          </Grid>
        </Grid>
        <Hidden xsDown implementation="css">
          <Grid container spacing={24}>
            <Grid item xs={2} />
            <Grid item xs={4} sm={4} m={4} l={4} xl={4}>
              <div className={classes.bitzGrid}>
                <Grid
                  className={classes.bitzLogoContainer}
                  container
                  spacing={24}
                >
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                    className={classes.noPaddingBottom}
                  >
                    <NavLink to={'/'}>
                      <img
                        className={classes.bitzRocket}
                        alt="Bitz LLC rocket logo"
                        src={mode.theme.enableDarkMode ? logoDark : logo}
                      />
                    </NavLink>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={2}
                    className={classes.noPaddingBottom}
                  >
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.bitzTitleDark
                          : classes.bitzTitleLight
                      }
                    >
                      Bitz LLC
                    </p>
                  </Grid>
                </Grid>
                <p className={classes.bitzSlogan}>
                  One Developer. Infinite Possibilities.
                </p>
                <p className={classes.bitzMessage}>
                  Made with{' '}
                  <IconButton
                    aria-label="favorite"
                    className={classes.heartIcon}
                  >
                    <FavoriteIcon />
                  </IconButton>{' '}
                  in Richmond, Virginia
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} m={6} l={6} xl={6}>
              <Grid container spacing={24}>
                <Grid item xs={6} sm={6} m={6} l={6} xl={6}>
                  <p className={classes.linkTitle} component="p">
                    Company
                  </p>
                  <NavLink to={'/about'} className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}>
                    <p className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight} component="p">
                      About
                    </p>
                  </NavLink>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.sendEmail}
                    component="p"
                  >
                    Contact
                  </p>
                  <NavLink to={'/faq'} className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}>
                    <p className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight} component="p">
                      FAQ
                    </p>
                  </NavLink>
                  <p className={classes.linkTitle} component="p">
                    Developer
                  </p>
                  <NavLink to={'/blog'} className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}>
                    <p className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight} component="p">
                      Blog
                    </p>
                  </NavLink>
                </Grid>
                <Grid item xs={6} sm={6} m={6} l={6} xl={6}>
                  <p className={classes.linkTitle} component="p">
                    Community
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToGithub}
                    component="p"
                  >
                    Github
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToTwitter}
                    component="p"
                  >
                    Twitter
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToFacebook}
                    component="p"
                  >
                    Facebook
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToLinkedIn}
                    component="p"
                  >
                    LinkedIn
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToAngelList}
                    component="p"
                  >
                    Angellist
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp implementation="css">
          <Grid className={classes.noPadding} container spacing={24}>
            <Grid
              item
              xs={12}
              sm={4}
              m={4}
              l={4}
              xl={4}
              className={classes.noPadding}
            >
              <div className={classes.bitzGrid}>
                <Grid
                  className={classes.bitzLogoContainer}
                  container
                  spacing={24}
                >
                  <Grid item xs={12} sm={2} className={classes.noPaddingBottom}>
                    <NavLink to={'/'}>
                      <img
                        className={classes.bitzRocket}
                        alt="Bitz LLC rocket logo"
                        src={mode.theme.enableDarkMode ? logoDark : logo}
                      />
                    </NavLink>
                  </Grid>
                </Grid>
                <p className={classes.bitzSlogan}>
                  One Developer. Infinite Possibilities.
                </p>
                <p className={classes.bitzMessage}>
                  Made with{' '}
                  <IconButton
                    aria-label="favorite"
                    className={classes.heartIcon}
                  >
                    <FavoriteIcon />
                  </IconButton>{' '}
                  in Richmond, Virginia
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              m={6}
              l={6}
              xl={6}
              className={classes.noPadding}
            >
              <Grid container spacing={24}>
                <Grid item xs={6} sm={6} m={6} l={6} xl={6}>
                  <p className={classes.linkTitle} component="p">
                    Company
                  </p>
                  <NavLink to={'/about'} className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}>
                    <p className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight} component="p">
                      About
                    </p>
                  </NavLink>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.sendEmail}
                    component="p"
                  >
                    Contact
                  </p>
                  <NavLink to={'/faq'} className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}>
                    <p className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight} component="p">
                      FAQ
                    </p>
                  </NavLink>
                  <p className={classes.linkTitle} component="p">
                    Developer
                  </p>
                  <NavLink to={'/blog'} className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}>
                    <p className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight} component="p">
                      Blog
                    </p>
                  </NavLink>
                </Grid>
                <Grid item xs={6} sm={6} m={6} l={6} xl={6}>
                  <p className={classes.linkTitle} component="p">
                    Community
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToGithub}
                    component="p"
                  >
                    Github
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToTwitter}
                    component="p"
                  >
                    Twitter
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToFacebook}
                    component="p"
                  >
                    Facebook
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToLinkedIn}
                    component="p"
                  >
                    LinkedIn
                  </p>
                  <p
                    className={mode.theme.enableDarkMode ? classes.linkDark : classes.linkLight}
                    onClick={this.goToAngelList}
                    component="p"
                  >
                    Angellist
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Paper>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles)(Footer);
