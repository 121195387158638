import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  backgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    padding: 50,
  },
  answerContainerDark: {
    fontFamily: fonts.base,
    backgroundColor: colors.darkThemeLightGreyBackground,
    borderRadius: 20,
    maxWidth: 490,
    minHeight: 230,
    display: 'block',
    margin: 'auto',
  },
  answerDark: {
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
    fontWeight: 600,
    paddingLeft: 30,
    paddingBottom: 30,
    paddingRight: 50,
  },
  answerVectorDark: {
    paddingLeft: 30,
    paddingTop: 30,
  },
  questionContainerDark: {
    fontFamily: fonts.base,
    backgroundColor: colors.darkThemeMediumGreyBackground,
    borderRadius: 20,
    maxWidth: 490,
    minHeight: 230,
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
  },
  questionDark: {
    fontFamily: fonts.base,
    color: colors.darkPrimary,
    fontWeight: 600,
    padding: 10,
    maxWidth: 250,
    display: 'block',
    margin: 'auto',
  },
  questionVectorDark: {
    paddingTop: 50,
  },
});

export default styles;
