import React from 'react';
import PropTypes from 'prop-types';

// Styles
import combineStyles from '../../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Body
 */
class Body extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {article, classes, mode} = this.props;
    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.bodyBackgroundDark
            : classes.bodyBackgroundLight
        }
      >
        {article.map((object, index) => (
          <div key={index.toString()}>
            {index === 0 ? (
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  {object.header}
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.bodyDark
                      : classes.bodyLight
                  }
                >
                  {object.message}
                </p>
              </div>
            ) : (
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.secondaryHeaderDark
                      : classes.secondaryHeaderLight
                  }
                >
                  {object.header}
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.bodyDark
                      : classes.bodyLight
                  }
                >
                  {object.message}
                </p>
              </div>
            )}
            {object.points !== undefined ? (
              <div>
                {object.points.map((bullet, index) => (
                  <li
                    className={
                      mode.theme.enableDarkMode
                        ? classes.bulletDark
                        : classes.bulletLight
                    }
                    key={index.toString()}
                  >
                    {bullet}
                  </li>
                ))}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles)(Body);
