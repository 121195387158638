import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  serviceBackgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
  },
  serviceTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.darkThemeWhiteText,
    marginBottom: 0,
    textAlign: 'center',
  },
  serviceSubTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
    minWidth: 300,
    maxWidth: 450,
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
  },
  serviceCardDark: {
    backgroundColor: colors.darkThemeLightGreyBackground,
    padding: 20,
    borderRadius: 10,
    minWidth: 260,
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
  },
  serviceTextDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    fontSize: 18,
  },
  serviceDescriptionDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
    fontSize: 14,
    marginTop: 60,
    marginBottom: 60,
  },
  vectorBackgroundDark: {
    marginTop: 20,
    borderRadius: 125,
    backgroundColor: colors.darkThemeMidnight,
    height: 250,
    width: 250,
    display: 'block',
    margin: 'auto',
    marginBottom: 60,
  },
});

export default styles;
