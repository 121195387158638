const colors = {
  primary: '#6067F1',
  darkPrimary: '#959DFF',
  darkThemeDarkGreyBackground: '#212121',
  darkThemeMediumGreyBackground: '#303030',
  darkThemeLightGreyBackground: '#424242',
  darkThemeMidnight: '#565664',
  darkThemeWhiteText: '#E1E1E4',
  lightThemeRed: '#rgba(245, 95, 68, .5)',
  lightThemeDarkBlue: '#707CB2',
  lightThemeLightBlue: '#626E98',
  lightThemeWhite: 'rgba(255, 255, 255, .5)',
  lightThemeLightGrey: '#A6B0D4',
  lightThemeDarkGrey: '#9198B2',
  lightThemeBlack: '#333',
  lightThemeBlueBackground: '#F3F7FE',
  white: '#FFFFFF',
};

const fonts = {
  base: 'Poppins',
  headerSize: 18,
  inputSize: 16,
  mainHeader: 20,
  secondaryText: 14,
};

const modal = {
  backgroundColor: '#FFFFFF',
  borderColor: 'rgba(0, 0, 0, 0.1)',
};

export {
  colors,
  fonts,
  modal,
};
