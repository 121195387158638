/* eslint-disable max-len */
import React from 'react';
import LinearRegressionContainer from '../../containers/posts/linear_regression';
import {Helmet} from 'react-helmet';

/**
 * Linear Regression
 */
class LinearRegression extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Linear Regression and grey kangaroo's - Bitz LLC</title>
          <link
            rel="canonical"
            href="http://brandonfitzwater.com/blog/linear-regression"
          />
          <meta
            name="description"
            content="Using python and machine learning techinques, linear regression in this case, to describe a possible linear relationsihp between grey kangaroo's nose width and nose length."
          />
          <meta
            name="keywords"
            content="python, machine learning, predictions, artifical intelligence, linear regression, beginner machine learning tutorial"
          />
        </Helmet>
        <LinearRegressionContainer />
      </div>
    );
  }
}

export default LinearRegression;
