/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

// Images
import devDark from '../../images/dev_dark.svg';
import devLight from '../../images/dev_light.svg';
import reactDark from '../../images/react_dark.svg';
import reactLight from '../../images/react_light.svg';
import javascriptDark from '../../images/javascript_dark.svg';
import javascriptLight from '../../images/javascript_light.svg';
import versionControlDark from '../../images/version_control_dark.svg';
import versionControlLight from '../../images/version_control_light.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Experience
 */
class Experience extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.experienceBackgroundDark
            : classes.experienceBackgroundLight
        }
      >
        <Hidden xsDown implementation="css">
          <Grid
            container
            spacing={24}
            className={
              mode.theme.enableDarkMode
                ? classes.gridWrapperDark
                : classes.gridWrapperLight
            }
          >
            <Grid item xs={12}>
              <p
                className={
                  mode.theme.enableDarkMode
                    ? classes.headerDark
                    : classes.headerLight
                }
              >
                Experience
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="developer leaning on mobile device"
                  src={mode.theme.enableDarkMode ? devDark : devLight}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  GroMobi
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>iOS Developer</li>
                  <li>April 2014 - January 2015</li>
                  <ul>
                    <li>Full Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  James Madison University
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>Web Developer</li>
                  <li>June 2015 - February 2016</li>
                  <ul>
                    <li>Full Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="individual sitting on react logo"
                  src={mode.theme.enableDarkMode ? reactDark : reactLight}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="javascript frameworks"
                  src={
                    mode.theme.enableDarkMode ? javascriptDark : javascriptLight
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  Northrop Grumman
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>Web Software Engineer</li>
                  <li>Software Developer Analyst II</li>
                  <li>February 2016 - Present</li>
                  <ul>
                    <li>Full Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  Trophy Game Tracker
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>Co-Founder / Developer</li>
                  <li>February 2017 - Present</li>
                  <ul>
                    <li>Part Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="developer and version control"
                  src={
                    mode.theme.enableDarkMode
                      ? versionControlDark
                      : versionControlLight
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp implementation="css">
          <Grid
            container
            spacing={24}
            className={
              mode.theme.enableDarkMode
                ? classes.gridWrapperDark
                : classes.gridWrapperLight
            }
          >
            <Grid item xs={12}>
              <p
                className={
                  mode.theme.enableDarkMode
                    ? classes.headerDark
                    : classes.headerLight
                }
              >
                Experience
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="developer leaning on mobile device"
                  src={mode.theme.enableDarkMode ? devDark : devLight}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  GroMobi
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>iOS Developer</li>
                  <li>April 2014 - January 2015</li>
                  <ul>
                    <li>Full Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="individual sitting on react logo"
                  src={mode.theme.enableDarkMode ? reactDark : reactLight}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  James Madison University
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>Web Developer</li>
                  <li>June 2015 - February 2016</li>
                  <ul>
                    <li>Full Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="javascript frameworks"
                  src={
                    mode.theme.enableDarkMode ? javascriptDark : javascriptLight
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  Northrop Grumman
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>Web Software Engineer</li>
                  <li>Software Developer Analyst II</li>
                  <li>February 2016 - Present</li>
                  <ul>
                    <li>Full Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.imagePanelDark
                    : classes.imagePanelLight
                }
              >
                <img
                  className={classes.experienceImage}
                  alt="developer and version control"
                  src={
                    mode.theme.enableDarkMode
                      ? versionControlDark
                      : versionControlLight
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.textPanel}>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.titleDark
                      : classes.titleLight
                  }
                >
                  Trophy Game Tracker
                </p>
                <ul
                  className={
                    mode.theme.enableDarkMode
                      ? classes.unorderedListDark
                      : classes.unorderedListLight
                  }
                >
                  <li>Co-Founder / Developer</li>
                  <li>February 2017 - Present</li>
                  <ul>
                    <li>Part Time</li>
                  </ul>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    );
  }
}

Experience.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(commonStyles, darkStyles, lightStyles);

export default withStyles(combinedStyles, {withTheme: true})(Experience);
