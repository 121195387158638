export const SET_THEME_MODE = 'SET_THEME_MODE';

const INITIAL_STATE = {
  status: '',
  enableDarkMode: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_THEME_MODE:
      return {
        ...state,
        enableDarkMode: action.payload,
        status: 'Setting theme mode',
      };
    default:
      return state;
  }
};
