import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  storeBackgroundLight: {
    backgroundColor: '#F3F7FE',
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      overflow: 'visible',
    },
  },
  storeTitleLight: {
    width: 300,
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.primary,
    marginLeft: 40,
    marginBottom: 0,
  },
  storeSubTitleLight: {
    color: '#9198B2',
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 40,
    marginBottom: 75,
  },
  huntClubCardLight: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 20,
    maxWidth: 350,
    minWidth: 295,
    marginBottom: 50,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  huntClubTextLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    fontSize: 18,
  },
  huntClubDescriptionLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#333',
    fontSize: 14,
    maxWidth: 300,
  },
  rxMazeCardLight: {
    backgroundColor: colors.primary,
    padding: 20,
    borderRadius: 20,
    maxWidth: 350,
    minWidth: 295,
    marginBottom: 50,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  rxMazeTextLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.white,
    fontSize: 18,
  },
  borderLight: {
    borderRightStyle: 'solid',
    borderRightWidth: 2,
    borderRightColor: '#d9d9db',
    marginLeft: 30,
  },
});

export default styles;
