import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import heroLight from '../../../images/about_hero.svg';
import heroDark from '../../../images/about_hero_dark.svg';

// Styles
import commonStyles from './styles/common/styles';
import {withStyles} from '@material-ui/core/styles';
import combineStyles from '../../../utilities/combine_styles';
import heroStylesDark from './styles/dark/styles';
import heroStylesLight from './styles/light/styles';
/**
 * Hero
 */
class Hero extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.heroBackgroundDark
            : classes.heroBackgroundLight
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <img
              className={classes.heroImage}
              alt="developer working with coding activity background"
              src={mode.theme.enableDarkMode ? heroDark : heroLight}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className={
                mode.theme.enableDarkMode
                  ? classes.heroHeadlineDark
                  : classes.heroHeadlineLight
              }
            >
              Meet the Champ
            </div>
            <div
              className={
                mode.theme.enableDarkMode
                  ? classes.heroMissionDark
                  : classes.heroMissionLight
              }
            >
              Get to know a little more about myself and what makes me tick. You
              may think I spend all my day coding, which is mainly true, but I
              do enjoy a variety of other things outside of work.
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    heroStylesDark,
    heroStylesLight
);

export default withStyles(combinedStyles, {withTheme: true})(Hero);
