/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Footer from '../../footer/about/footer';
import Header from '../../posts/header/header';
import NavBar from '../../app_bar/appbar';
import {NavLink} from 'react-router-dom';

// Theming
import combineStyles from '../../../utilities/combine_styles';
import {withStyles} from '@material-ui/core/styles';
import commonPostStyles from '../../../theme/posts/common/styles';
import darkPostStyles from '../../../theme/posts/dark/styles';
import lightPostStyles from '../../../theme/posts/light/styles';
import dialogStylesDark from '../../../theme/dialogs/dark/styles';
import dialogStylesLight from '../../../theme/dialogs/light/styles';

// Images
import logoDark from '../../../images/bitz_logo_green.svg';
import logoLight from '../../../images/bitz_logo_alt.svg';
import iterativeLearningDark from '../../../images/iterative_learning_dark.svg';
import iterativeLearningLight from '../../../images/iterative_learning_light.svg';

// Visualization
import MathJax from 'react-mathjax2';

// Header info
const date = 'June 26, 2019';
const type = 'Developer';
const title = 'Loss Reduction';
const author = 'Brandon Fitzwater';

/**
 * Iterative Learning
 */
class IterativeLearning extends React.Component {
  state = {
    height: 0,
    width: 0,
    open: false,
    dialogTitle: '',
    dialogBody: '',
  };

  /**
   * Lifecyle method
   */
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * Lifecyle method
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * @Function handleClose
   * @Purpose closes terminology dialog
   */
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  /**
   * @Function updateWindowDimensions
   * @Purpose updates width and height of the window
   */
  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  /**
   * @Function openDialog
   * @Purpose opens generic dialog
   * @param {String} title title of dialog
   * @param {String} body message to display in body
   */
  setTerminologyText = (title, body) => {
    this.setState({
      open: true,
      dialogTitle: title,
      dialogBody: body,
    });
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogDark
                : classes.dialogLight
            }
          >
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogTitleDark
                  : classes.dialogTitleLight
              }
            >
              {this.state.dialogTitle}
            </p>
          </DialogTitle>
          <DialogContent
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogDark
                : classes.dialogLight
            }
          >
            <p
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogDescriptionDark
                  : classes.dialogDescriptionLight
              }
            >
              {this.state.dialogBody}
            </p>
          </DialogContent>
          <DialogActions
            className={
              this.props.theme.enableDarkMode
                ? classes.dialogFooterDark
                : classes.dialogFooterLight
            }
          >
            <Button
              onClick={this.handleClose}
              className={
                this.props.theme.enableDarkMode
                  ? classes.dialogButtonTextDark
                  : classes.dialogButtonTextLight
              }
              autoFocus
            >
              Got it
            </Button>
          </DialogActions>
        </Dialog>
        <NavBar mode={this.props} />
        <Header
          date={date}
          type={type}
          title={title}
          author={author}
          mode={this.props}
        />
        <Grid
          container
          spacing={24}
          className={
            this.props.theme.enableDarkMode
              ? classes.backgroundHeroDark
              : classes.backgroundHeroLight
          }
        >
          <Grid item xs={12}>
            <div>
              <div className={classes.card}>
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.cardImageDarkGrey
                      : classes.cardImagePrimary
                  }
                >
                  <img
                    className={classes.bitzRocket}
                    alt="Bitz LLC rocket logo"
                    src={
                      this.props.theme.enableDarkMode ? logoDark : logoLight
                    }
                  />
                  <p className={classes.headline}>Machine Learning</p>
                  <p
                    className={
                      this.props.theme.enableDarkMode
                        ? classes.titleGreen
                        : classes.title
                    }
                  >
                    Loss Reduction
                  </p>
                  <p className={classes.shortDescription}>
                    Iterative Learning
                  </p>
                </div>
                <div
                  className={
                    this.props.theme.enableDarkMode
                      ? classes.backgroundDark
                      : classes.backgroundLight
                  }
                >
                  <div>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.titleDark
                          : classes.titleLight
                      }
                    >
                      Introduction
                    </p>
                    <p
                      className={
                        this.props.theme.enableDarkMode
                          ? classes.bodyDark
                          : classes.bodyLight
                      }
                    >
                      In the{' '}
                      <NavLink
                        to={'/blog/loss'}
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.internalLinkDark
                            : classes.internalLinkLight
                        }
                      >
                        last blog post
                      </NavLink>{' '}
                      we learned that loss is essentially how bad a model is
                      and comes from a poor prediction made on a single
                      example. We learned that Mean Square Error (MSE) is
                      one commonly-used Machine Learning algorithm, but it
                      is not the best loss function for all circumstances.
                      In this post we will be exploring how a machine
                      learning model reduces loss by taking an iterative
                      approach.
                    </p>

                    <div>
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.secondaryHeaderDark
                            : classes.secondaryHeaderLight
                        }
                      >
                        Iterative Learning
                      </p>

                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        Remember the retro game Guess Who? This game is how
                        I like to think of iterative learning. The gist of
                        the game is you and your opponent select a character
                        and take turns asking questions to narrow down who
                        your opponent has selected. For example, "Does your
                        character have blonde hair?". This would equate to
                        your initial weight vector guess in your model (Let{' '}
                        <MathJax.Context input="ascii">
                          <b
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>w_1</MathJax.Node>
                          </b>
                        </MathJax.Context>{' '}
                        be 0). Once the guess is made we see what the loss
                        is. Notice as you continue to ask questions (ex. "Is
                        your opponent bald?") you will be getting closer to
                        finding the character your opponent has selected.
                        This is just how iterative learning works. The game
                        concludes when either you or your opponent correctly
                        guess one anothers chosen character. The chosen
                        character would represent the best possible model!
                      </p>

                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        This guess and check method in the machine learning
                        is one of many techinques used to train a model and
                        is illustrated in the graphic below:
                      </p>
                    </div>

                    <div className={classes.chartContainer}>
                      <img
                        className={classes.blogImage}
                        alt="demonstrating iterative learning"
                        src={
                          this.props.theme.enableDarkMode
                            ? iterativeLearningDark
                            : iterativeLearningLight
                        }
                      />
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.figureTextDark
                            : classes.figureTextLight
                        }
                      >
                        Figure 1. How iterative learning is used on machine
                        learning models.
                      </p>
                    </div>

                    <div>
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.titleDark
                            : classes.titleLight
                        }
                      >
                        Breakdown
                      </p>
                    </div>

                    <div>
                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        Dissecting the picture above we can see the "Model"
                        takes in a feature as input, makes some sort of
                        prediction, and returns that value as output. Let's
                        look at a how this might be written mathematically.
                      </p>

                      <div
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Context input="ascii">
                          <div
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>y' = w_1 x_1 + b</MathJax.Node>
                          </div>
                        </MathJax.Context>
                      </div>

                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        So what should our initial values be for our weight
                        vector (
                        <MathJax.Context input="ascii">
                          <b
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>w_1</MathJax.Node>
                          </b>
                        </MathJax.Context>
                        ) and{' '}
                        <b
                          className={
                            this.props.theme.enableDarkMode
                              ? classes.definitionTextDark
                              : classes.definitionTextLight
                          }
                          onClick={() =>
                            this.setTerminologyText(
                                'Bias',
                                'An intercept or offset from an origin.'
                            )
                          }
                        >
                          bias
                        </b>{' '}
                        (
                        <MathJax.Context input="ascii">
                          <b
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>b</MathJax.Node>
                          </b>
                        </MathJax.Context>
                        )? In{' '}
                        <b
                          className={
                            this.props.theme.enableDarkMode
                              ? classes.definitionTextDark
                              : classes.definitionTextLight
                          }
                          onClick={() =>
                            this.setTerminologyText(
                                'Linear Regression',
                                'A type of model that outputs a continuous value from a linear combination of input features.'
                            )
                          }
                        >
                          linear regression
                        </b>{' '}
                        it doesn't matter so, let's just use trivial values
                        of zero. Now suppose that our first feature value is
                        5. Our prediction function spit out the following:
                      </p>

                      <div
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.formulaDark
                            : classes.formulaLight
                        }
                      >
                        <MathJax.Context input="ascii">
                          <div
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>y' = 0 (5) + 0 = 5</MathJax.Node>
                          </div>
                        </MathJax.Context>
                      </div>

                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        The "Evaluate Loss" section is the loss function the
                        model will use. In the last blog post I spoke about{' '}
                        <b
                          className={
                            this.props.theme.enableDarkMode
                              ? classes.definitionTextDark
                              : classes.definitionTextLight
                          }
                          onClick={() =>
                            this.setTerminologyText(
                                'Squared Loss',
                                'The loss function used in linear regression. (Also known as L2 Loss.) This function calculates the squares of the difference between a model\'s predicted value for a labeled example and the actual value of the label. Due to squaring, this loss function amplifies the influence of bad predictions. That is, squared loss reacts more strongly to outliers than L1 loss.'
                            )
                          }
                        >
                          squared loss
                        </b>
                        , so let's use that for this scenario which takes in
                        two input parameters as follows:
                      </p>

                      <div
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.unorderedListDark
                            : classes.unorderedListLight
                        }
                      >
                        <ul>
                          <li>
                            <MathJax.Context input="ascii">
                              <div>
                                <b
                                  className={
                                    this.props.theme.enableDarkMode
                                      ? classes.formulaDark
                                      : classes.formulaLight
                                  }
                                >
                                  <MathJax.Node>y'</MathJax.Node>
                                </b>
                                {'  '}is the models predictions for{' '}
                                <b
                                  className={
                                    this.props.theme.enableDarkMode
                                      ? classes.definitionTextDark
                                      : classes.definitionTextLight
                                  }
                                  onClick={() =>
                                    this.setTerminologyText(
                                        'Feature',
                                        'An input variable used in making predictions.'
                                    )
                                  }
                                >
                                  feature
                                </b>{' '}
                                <MathJax.Context input="ascii">
                                  <MathJax.Node>x</MathJax.Node>
                                </MathJax.Context>{' '}
                                .
                              </div>
                            </MathJax.Context>
                          </li>
                          <li>
                            <MathJax.Context input="ascii">
                              <div>
                                <b
                                  className={
                                    this.props.theme.enableDarkMode
                                      ? classes.formulaDark
                                      : classes.formulaLight
                                  }
                                >
                                  <MathJax.Node>y</MathJax.Node>
                                </b>
                                {'  '}is the{' '}
                                <b
                                  className={
                                    this.props.theme.enableDarkMode
                                      ? classes.definitionTextDark
                                      : classes.definitionTextLight
                                  }
                                  onClick={() =>
                                    this.setTerminologyText(
                                        'Label',
                                        'A feature is an input variable—the x variable in simple linear regression.'
                                    )
                                  }
                                >
                                  label
                                </b>{' '}
                                corresponding to feature{' '}
                                <MathJax.Context input="ascii">
                                  <MathJax.Node>x</MathJax.Node>
                                </MathJax.Context>{' '}
                                .
                              </div>
                            </MathJax.Context>
                          </li>
                        </ul>
                      </div>

                      <p
                        className={
                          this.props.theme.enableDarkMode
                            ? classes.bodyDark
                            : classes.bodyLight
                        }
                      >
                        Lastly, we come to the "Evaluate Parameter Updates"
                        part of the illustration. In this step the system
                        looks at the value returned from the loss function
                        and creates new values for the bias (
                        <MathJax.Context input="ascii">
                          <b
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>b</MathJax.Node>
                          </b>
                        </MathJax.Context>
                        ) and weight vector (
                        <MathJax.Context input="ascii">
                          <b
                            className={
                              this.props.theme.enableDarkMode
                                ? classes.formulaDark
                                : classes.formulaLight
                            }
                          >
                            <MathJax.Node>w_1</MathJax.Node>
                          </b>
                        </MathJax.Context>
                        ). Without going too far in the weeds, just assume
                        that this step produces new values and then the
                        system re-evaluates all those features against all
                        those labels, creating a new value for the loss
                        function, which in return creates new parameter
                        values. This process continues iterating until the
                        algorithm discovers the model parameters with the
                        lowest possible loss. Generally, you keep iterating
                        until the model has converged. This means the
                        overall loss stops changing or at least changes very
                        slowly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Footer mode={this.props} />
      </div>
    );
  }
}

IterativeLearning.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonPostStyles,
    darkPostStyles,
    dialogStylesDark,
    dialogStylesLight,
    lightPostStyles
);

export default withStyles(combinedStyles)(IterativeLearning);
