import {fonts, colors} from '../../../theme';

const styles = (theme) => ({
  dialogDark: {
    backgroundColor: colors.darkThemeLightGreyBackground,
  },
  dialogFooterDark: {
    backgroundColor: colors.darkThemeLightGreyBackground,
    margin: 0,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
  },
  dialogTitleDark: {
    margin: 0,
    fontFamily: 'Poppins !important',
    fontWeight: 600,
    color: '#A5D6A7',
    backgroundColor: colors.darkThemeLightGreyBackground,
  },
  dialogDescriptionDark: {
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
  },
  dialogButtonTextDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#A5D6A7',
  },
});

export default styles;
