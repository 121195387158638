import {fonts, colors} from '../../../theme';

const styles = (theme) => ({
  appbarLight: {
    background: colors.white,
    boxShadow: 'none',
    height: 80,
  },
  buttonTextLight: {
    color: colors.primary,
    fontFamily: fonts.base,
  },
  companyNameLight: {
    marginLeft: 10,
    flexGrow: 1,
    color: colors.primary,
    fontFamily: fonts.base,
    fontWeight: 600,
  },
  menuButtonLight: {
    'color': colors.primary,
  },
  mobilePopperLight: {
    'backgroundColor': '#f5f7ff',
    '-webkit-box-shadow': 'rgba(29,29,58,.6) 0 11px 100px',
    'box-shadow': '0 11px 100px rgba(29,29,58,.6)',
    'borderRadius': 12,
  },
  popperDesktopLight: {
    'backgroundColor': '#f5f7ff',
    'width': 400,
    '-webkit-box-shadow': 'rgba(29,29,58,.6) 0 11px 100px',
    'boxShadow': '0 11px 100px rgba(29,29,58,.6)',
    'borderRadius': 12,
  },
  switchLight: {
    'color': colors.primary,
    '&$switchCheckedLight': {
      'color': colors.primary,
      '& + $switchBarLight': {
        backgroundColor: colors.primary,
      },
    },
  },
  switchBarLight: {},
  switchCheckedLight: {},
});

export default styles;
