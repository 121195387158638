/* eslint-disable max-len */
import {fonts, colors} from '../../../theme';
import backgroundPrimaryDark from '../../../images/lines_circles_dark.svg';

const styles = (theme) => ({
  bitzRocket: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 90,
      width: 40,
    },
    [theme.breakpoints.down('xs')]: {
      width: 30,
      marginTop: 25,
    },
  },
  blogImage: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  card: {
    borderRadius: 10,
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    padding: 20,
  },
  cardImageDarkGrey: {
    'backgroundImage': `url(${backgroundPrimaryDark})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundColor': colors.darkThemeMediumGreyBackground,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 800,
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    'display': 'block',
    'margin': 'auto',
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
    'margin-bottom': 20,
  },
  chartContainer: {
    marginTop: 30,
    textAlign: 'left',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  headline: {
    color: '#e0dfe0',
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  internalLinkDark: {
    textDecoration: 'none',
    color: colors.darkPrimary,
  },
  internalLinkLight: {
    textDecoration: 'none',
    color: colors.primary,
  },
  listContainer: {
    marginBottom: 50,
  },
  noMargin: {
    margin: 0,
  },
  shortDescription: {
    color: '#e0dfe0',
    fontFamily: fonts.base,
    marginTop: 0,
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  title: {
    color: colors.white,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  titleGreen: {
    color: '#A5D6A7',
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 0,
    fontSize: 40,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
});

export default styles;
