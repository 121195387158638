import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  textContainer: {
    marginBottom: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  bitzLogoContainer: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 'auto',
    marginLeft: -10,
  },
  addressLink: {
    color: '#5f6368',
    marginTop: 20,
    marginRight: 40,
  },
  bitzRocket: {
    width: 50,
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  bitzSlogan: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#A6B0D4',
    marginTop: 10,
    marginBottom: 0,
  },
  bitzMessage: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: '#A6B0D4',
    marginTop: 0,
  },
  linkTitle: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: colors.lightThemeLightGrey,
  },
  noPaddingBottom: {
    paddingBottom: '0 !important',
  },
  heartIcon: {
    '&:hover': {
      backgroundColor: '#F3F7FE !important',
      cursor: 'default',
    },
    'color': 'rgba(245, 95, 68, .5)',
    'marginBottom': 5,
    'width': 30,
  },
  spaceships: {
    width: '100%',
  },
  mobileLinksContainer: {
    display: 'block',
    margin: 'auto',
  },
  noPadding: {
    padding: '0px !important',
  },
});

export default styles;
