import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  processBackgroundDark: {
    backgroundColor: colors.darkThemeMediumGreyBackground,
    paddingBottom: 80,
    paddingTop: 40,
  },
  processTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.darkPrimary,
    marginBottom: 0,
    marginLeft: 30,
  },
  processCardTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: colors.darkPrimary,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  processCardSubTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  processSubTitleDark: {
    marginTop: 13,
    color: colors.darkThemeWhiteText,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginBottom: 73,
    maxWidth: 400,
    marginLeft: 30,
  },
  processCardDark: {
    backgroundColor: colors.darkThemeLightGreyBackground,
    borderRadius: 12,
    marginTop: 30,
    padding: 20,
  },
  vectorBackgroundDark: {
    backgroundColor: colors.darkThemeMidnight,
    display: 'block',
    margin: 'auto',
    height: 150,
    width: 150,
    borderRadius: 180,
  },
});

export default styles;
