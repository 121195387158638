import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  heroBackgroundLight: {
    backgroundColor: '#F3F7FE',
    paddingTop: 70,
    paddingBottom: 70,
  },
  contactLight: {
    'backgroundColor': '#6067F1',
    'boxShadow': '0 11px 30px rgba(97, 108, 238, .25)',
    'display': 'block',
    'marginTop': 30,
    'height': 45,
    'width': 120,
    'borderRadius': 30,
    '&:hover': {
      backgroundColor: '#6168e6',
    },
  },
  heroHeadlineLight: {
    marginTop: 0,
    maxWidth: 460,
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    display: 'block',
    margin: 'auto',
  },
  heroHeadlineMobileLight: {
    marginTop: 0,
    fontSize: 34,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    display: 'block',
    margin: 'auto',
    marginLeft: 30,
  },
  heroMissionLight: {
    marginTop: 30,
    maxWidth: 460,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#9198B2',
  },
  heroMissionMobileLight: {
    marginTop: 30,
    maxWidth: 350,
    marginLeft: 30,
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#9198B2',
  },
});

export default styles;
