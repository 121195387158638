import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import appDevelopmentSvg from '../../images/app_development.svg';
import launchSvg from '../../images/launch.svg';
import launchDarkSvg from '../../images/launch_dark.svg';
import productDesignSvg from '../../images/product_design.svg';
import webDevelopmentSvg from '../../images/web_development.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Services
 */
class Services extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.serviceBackgroundDark
            : classes.serviceBackgroundLight
        }
      >
        <Grid container spacing={24} className={classes.gridWrapper}>
          <Grid item xs={12} sm={12}>
            <h1
              className={
                mode.theme.enableDarkMode
                  ? classes.serviceTitleDark
                  : classes.serviceTitleLight
              }
            >
              Core Services
            </h1>
            <p
              className={
                mode.theme.enableDarkMode
                  ? classes.serviceSubTitleDark
                  : classes.serviceSubTitleLight
              }
            >
              Cultivating expertise in every stage of product creation,
              resulting in extraordinary digital experiences.
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.storeCard}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.serviceCardDark
                    : classes.serviceCardLight
                }
              >
                <div
                  className={
                    mode.theme.enableDarkMode
                      ? classes.vectorBackgroundDark
                      : classes.vectorBackgroundLight
                  }
                >
                  <img
                    className={classes.appDevelopmentImage}
                    alt="user interactive with mobile application"
                    src={appDevelopmentSvg}
                  />
                </div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceTextDark
                      : classes.serviceTextLight
                  }
                >
                  App Development
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceDescriptionDark
                      : classes.serviceDescriptionLight
                  }
                >
                  iOS
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceDescriptionDark
                      : classes.serviceDescriptionLight
                  }
                >
                  Android
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceDescriptionDark
                      : classes.serviceDescriptionLight
                  }
                >
                  Cross Platform
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.storeCard}>
              <div className={classes.launch}>
                <img
                  className={classes.launchImage}
                  alt="user interactive with mobile application"
                  src={mode.theme.enableDarkMode ? launchDarkSvg : launchSvg}
                />
                <div
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceCardDark
                      : classes.serviceCardLight
                  }
                >
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.vectorBackgroundDark
                        : classes.vectorBackgroundLight
                    }
                  >
                    <img
                      className={classes.webDevelopmentImage}
                      alt="desktop sitting on table near plants"
                      src={webDevelopmentSvg}
                    />
                  </div>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.serviceTextDark
                        : classes.serviceTextLight
                    }
                  >
                    Web Development
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.serviceDescriptionDark
                        : classes.serviceDescriptionLight
                    }
                  >
                    Responsive Web Design
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.serviceDescriptionDark
                        : classes.serviceDescriptionLight
                    }
                  >
                    Agile
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.serviceDescriptionDark
                        : classes.serviceDescriptionLight
                    }
                  >
                    Search Engine Optimization
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.storeCard}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.serviceCardDark
                    : classes.serviceCardLight
                }
              >
                <div
                  className={
                    mode.theme.enableDarkMode
                      ? classes.vectorBackgroundDark
                      : classes.vectorBackgroundLight
                  }
                >
                  <img
                    className={classes.webDevelopmentImage}
                    alt="design using Sketch and Adobe XD"
                    src={productDesignSvg}
                  />
                </div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceTextDark
                      : classes.serviceTextLight
                  }
                >
                  Product Design
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceDescriptionDark
                      : classes.serviceDescriptionLight
                  }
                >
                  User Experience
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceDescriptionDark
                      : classes.serviceDescriptionLight
                  }
                >
                  Visual Design
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.serviceDescriptionDark
                      : classes.serviceDescriptionLight
                  }
                >
                  Prototyping
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Services.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Services);
