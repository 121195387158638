import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: colors.white,
    fontFamily: fonts.base,
    fontSize: 12,
  },
  hero: {
    marginTop: 30,
    height: 500,
    overflow: 'hidden',
  },
  heroVector: {
    margin: 'auto',
    display: 'block',
    width: '100%',
  },
  heroVectorMobile: {
    margin: 'auto',
    display: 'block',
    width: '85%',
  },
  heroImage: {
    display: 'block',
    margin: 'auto',
  },
  heroImageMobile: {
    display: 'block',
    margin: 'auto',
    height: 100,
  },
});

export default styles;
