const styles = (theme) => ({
  vectorImage: {
    marginLeft: 5,
    marginTop: 5,
  },
  processContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  noPaddingTop: {
    paddingTop: '0px !important',
  },
  noPaddingBottom: {
    paddingBottom: '0px !important',
  },
});

export default styles;
