import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  backgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
  },
  headerDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    color: colors.darkPrimary,
  },
});

export default styles;
