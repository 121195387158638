/* eslint-disable max-len */
import {fonts, colors} from '../../../../theme';
import BackgroundDark from '../../../../images/hero_blog_alt.svg';

const styles = (theme) => ({
  introBackgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    padding: 100,
    [theme.breakpoints.down('lg')]: {
      padding: 100,
    },
    [theme.breakpoints.down('md')]: {
      padding: 100,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 48,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 16,
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  introContainerDark: {
    'borderRadius': 12,
    'boxShadow': 'none',
    'backgroundColor': colors.darkThemeLightGreyBackground,
    'backgroundImage': `url(${BackgroundDark})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      paddingBottom: 50,
    },
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
  },
  headlineDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
  },
  titleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    marginTop: 10,
    marginBottom: 10,
    color: colors.darkPrimary,
  },
  shortDescriptionDark: {
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
    marginBottom: 50,
  },
});

export default styles;
