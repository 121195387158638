// Import all reducers
import {combineReducers} from 'redux';
import theme from './theme';

// Add them to the global store
const allReducers = combineReducers({
  theme: theme,
});

export default allReducers;
