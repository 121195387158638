/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../components/app_bar/appbar';
import Hero from '../components/hero/blog/hero';
import Introduction from '../components/introduction/intro';
import Overview from '../components/posts/overview/overview';
import Footer from '../components/footer/home/footer';

/**
 * Blog
 */
class Blog extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <NavBar mode={this.props} />
        <Hero mode={this.props} />
        <Introduction mode={this.props} />
        <Overview mode={this.props} />
        <Footer mode={this.props} />
      </div>
    );
  }
}

Blog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Blog;
