/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

// Images
import heroDark from '../../../images/questions_dark.svg';
import heroLight from '../../../images/questions.svg';

// Styles
import styles from './styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Hero
 */
class Hero extends React.Component {
  sendEmail = () => {
    window.location.href =
      'mailto:brandonfitzh2o@gmail.com?subject=Bitz LLC Inquiry';
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.heroBackgroundDark
            : classes.heroBackgroundLight
        }
      >
        <Hidden xsDown implementation="css">
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.heroHeadlineDark
                    : classes.heroHeadlineLight
                }
              >
                Frequently
                <br />
                Asked Questions.
              </div>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.heroMissionDark
                    : classes.heroMissionLight
                }
              >
                Before you shoot me an email, take a moment to see if one of
                my previous clients has asked the same question. It could be
                just the response you’re looking for!
                <Button
                  className={
                    mode.theme.enableDarkMode
                      ? classes.contactDark
                      : classes.contactLight
                  }
                  onClick={this.sendEmail}
                  variant="contained"
                >
                  <a href="#contact" className={classes.link}>
                    Contact
                  </a>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                className={classes.heroImage}
                alt={
                  mode.theme.enableDarkMode
                    ? 'woman asking questions dark theme'
                    : 'woman asking questions light theme'
                }
                src={
                  mode.theme.enableDarkMode
                    ? heroDark
                    : heroLight
                }
              />
            </Grid>
          </Grid>
        </Hidden>
        <Grid container spacing={24}>
          <Hidden smUp implementation="css">
            <Grid item xs={12} sm={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.heroHeadlineMobileDark
                    : classes.heroHeadlineMobileLight
                }
              >
                Frequently
                <br />
                Asked Questions.
              </div>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.heroMissionMobileDark
                    : classes.heroMissionMobileLight
                }
              >
                Before you shoot me an email, take a moment to see if one of
                my previous clients has asked the same question. It could be
                just the response you’re looking for!
                <Button
                  className={
                    mode.theme.enableDarkMode
                      ? classes.contactDark
                      : classes.contactLight
                  }
                  onClick={this.sendEmail}
                  variant="contained"
                >
                  <a href="#contact" className={classes.link}>
                    Contact
                  </a>
                </Button>
              </div>
              <img
                className={classes.heroVectorMobile}
                alt={
                  mode.theme.enableDarkMode
                    ? 'woman asking questions dark theme'
                    : 'woman asking questions light theme'
                }
                src={mode.theme.enableDarkMode ? heroDark : heroLight}
              />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

};
export default withStyles(styles, {withTheme: true})(Hero);
