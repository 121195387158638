import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  authorDark: {
    textAlign: 'center',
    color: colors.darkPrimary,
    fontSize: 15,
    marginTop: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    paddingBottom: 30,
    marginBottom: 0,
  },
  dateStampDark: {
    color: colors.darkThemeWhiteText,
    fontSize: 14,
    fontFamily: fonts.base,
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 100,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
    },
  },
  headerBackgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
  },
  titleDark: {
    textAlign: 'center',
    color: colors.darkPrimary,
    fontSize: 50,
    marginTop: 0,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    maxWidth: 710,
    display: 'block',
    margin: 'auto',
    padding: 10,
  },
});

export default styles;
