const styles = (theme) => ({
  mobileWebContainer: {
    padding: 20,
    paddingBottom: 55,
  },
  mobileContainer: {
    borderRadius: 20,
    padding: 10,
    marginTop: 20,
    position: 'relative',
  },
  webContainer: {
    borderRadius: 20,
    padding: 10,
    marginTop: 20,
    position: 'relative',
  },
});

export default styles;
