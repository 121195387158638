/* eslint-disable max-len */
import {fonts, colors} from '../../../../theme';
import BackgroundLight from '../../../../images/hero_blog.svg';

const styles = (theme) => ({
  introBackgroundLight: {
    backgroundColor: colors.white,
    padding: 100,
    [theme.breakpoints.down('lg')]: {
      padding: 100,
    },
    [theme.breakpoints.down('md')]: {
      padding: 100,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 48,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 16,
      marginTop: 50,
      marginBottom: 50,
    },
  },
  introContainerLight: {
    'borderRadius': 12,
    'boxShadow': '8px 14px 38px rgba(39,44,49,.06), 1px 3px 8px rgba(39,44,49,.03)',
    'backgroundColor': '#F3F7FE',
    'backgroundImage': `url(${BackgroundLight})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      paddingBottom: 50,
    },
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
  },
  headlineLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
  },
  titleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    marginTop: 10,
    marginBottom: 10,
    color: colors.primary,
  },
  shortDescriptionLight: {
    fontFamily: fonts.base,
    color: colors.primary,
    marginBottom: 50,
  },
});

export default styles;
