/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';
import {NavLink} from 'react-router-dom';

// Images
import logoDark from '../../images/bitz_logo_purple.svg';
import logoLight from '../../images/bitz_logo_light.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Intro
 */
class Intro extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.introBackgroundDark
            : classes.introBackgroundLight
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <NavLink to={'/blog/introduction'} className={classes.noUnderline}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.introContainerDark
                    : classes.introContainerLight
                }
              >
                <div className={classes.textContainer}>
                  <img
                    className={classes.bitzRocket}
                    alt="Bitz LLC rocket logo"
                    src={mode.theme.enableDarkMode ? logoDark : logoLight}
                  />
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.headlineDark
                        : classes.headlineLight
                    }
                  >
                    Ready. Set. Go.
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.titleDark
                        : classes.titleLight
                    }
                  >
                    Introduction
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.shortDescriptionDark
                        : classes.shortDescriptionLight
                    }
                  >
                    Join the quest to mastering machine learning!
                  </p>
                </div>
              </div>
            </NavLink>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Intro.propTypes = {
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Intro);
