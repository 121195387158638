import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  rootLight: {
    paddingBottom: 16,
    flexGrow: 1,
    backgroundColor: '#F3F7FE',
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  linkLight: {
    'textDecoration': 'none',
    'fontFamily': fonts.base,
    'fontSize': 14,
    'color': '#707CB2',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bitzTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 25,
    color: colors.primary,
    width: 70,
  },
});

export default styles;
