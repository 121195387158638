// eslint-disable-next-line no-unused-vars
import React, {Component} from 'react';
// eslint-disable-next-line no-unused-vars
import AppContainer from '../containers/app';

/**
 * Entry point for the web app
 */
class App extends Component {
  /**
  * Lifecyle method
  * @return {component}container
  */
  render() {
    return <AppContainer />;
  }
}

export default App;

