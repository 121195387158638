import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import heroDark from '../../../images/machine_learning_dark.svg';
import heroLight from '../../../images/machine_learning.svg';

// Styles
import styles from './styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Hero
 */
class Hero extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.heroBackgroundDark
            : classes.heroBackgroundLight
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <img
              className={classes.heroImage}
              alt="developer working with coding activity background"
              src={mode.theme.enableDarkMode ? heroDark : heroLight}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className={
                mode.theme.enableDarkMode
                  ? classes.heroHeadlineDark
                  : classes.heroHeadlineLight
              }
            >
              Bitz Blog
            </div>
            <div
              className={
                mode.theme.enableDarkMode
                  ? classes.heroMissionDark
                  : classes.heroMissionLight
              }
            >
              Join my quest to learn something new each and every day. Plus I’ll
              be sharing my experience along the way!
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(Hero);
