import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  storeCard: {
    padding: 20,
  },
  rxMazeDescription: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.lightThemeWhite,
    fontSize: 14,
  },
  mobileMockups: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
    },
    marginTop: -120,
    marginLeft: -50,
  },
  purpleAndroidLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
    'height': 40,
    'marginLeft': 30,
  },
  purpleAppleLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
    'height': 40,
  },
  whiteAppleLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
    'height': 40,
  },
  whiteAndroidLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
    'height': 40,
    'marginLeft': 30,
  },
  iconContainer: {
    display: 'inline-flex',
  },
});

export default styles;
