import {fonts, colors} from '../../../theme';

const styles = (theme) => ({
  appbarDark: {
    background: colors.darkThemeDarkGreyBackground,
    boxShadow: 'none',
    height: 80,
  },
  buttonTextDark: {
    '&:hover': {
      backgroundColor: colors.darkThemeLightGreyBackground,
    },
    'color': colors.darkPrimary,
    'fontFamily': fonts.base,
    '&:disabled': {
      color: 'rgba(149, 157, 255, .26)',
    },
  },
  companyNameDark: {
    marginLeft: 10,
    flexGrow: 1,
    color: colors.darkPrimary,
    fontFamily: fonts.base,
    fontWeight: 600,
  },
  darkThemeIcons: {
    color: '#d7ddf1',
    top: 8,
    position: 'relative',
  },
  mobilePopperDark: {
    'backgroundColor': '#3f3c56',
    '-webkit-box-shadow': 'rgba(29,29,58,.6) 0 11px 100px',
    'box-shadow': '0 11px 100px rgba(29,29,58,.6)',
    'borderRadius': 12,
  },
  popperDesktopDark: {
    'backgroundColor': '#3f3c56',
    'width': 400,
    '-webkit-box-shadow': 'rgba(29,29,58,.6) 0 11px 100px',
    'boxShadow': '0 11px 100px rgba(29,29,58,.6)',
    'borderRadius': 12,
  },
  switchDark: {
    'color': colors.darkPrimary,
    '&$switchCheckedDark': {
      'color': colors.darkPrimary,
      '& + $switchBarDark': {
        backgroundColor: colors.darkPrimary,
      },
    },
  },
  switchBarDark: {},
  switchCheckedDark: {},
});

export default styles;
