/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';

import routes from '../routes/views';

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      }
      return <Route exact path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

/**
 * Entry Point for Web App
 */
class App extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return <div>{switchRoutes}</div>;
  }
}

App.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(App));

