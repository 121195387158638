/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BlogContainer from '../containers/blog';
import {Helmet} from 'react-helmet';

/**
 * Blog
 */
class Blog extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blog - Bitz LLC</title>
          <meta
            name="description"
            content="Join my quest to learn something new each and every day. Plus I’ll be sharing my experience along the way!"
          />
          <meta
            name="keywords"
            content="bitz blog, brandon fitzwater blog, developer blog, programming tutorials, code for beginners, learn to code, tech blog, machine learning, math, computer science, python, data science"
          />
        </Helmet>
        <BlogContainer />
      </div>
    );
  }
}

Blog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Blog;
