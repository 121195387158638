import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  backgroundLight: {
    backgroundColor: colors.white,
    padding: 50,
  },
  grid: {
    marginBottom: 40,
  },
  answerContainerLight: {
    fontFamily: fonts.base,
    backgroundColor: colors.lightThemeBlueBackground,
    borderRadius: 20,
    maxWidth: 490,
    minHeight: 230,
    display: 'block',
    margin: 'auto',
  },
  answerLight: {
    fontFamily: fonts.base,
    color: colors.lightThemeBlack,
    fontWeight: 600,
    paddingLeft: 30,
    paddingBottom: 30,
    paddingRight: 50,
  },
  answerVectorLight: {
    paddingLeft: 30,
    paddingTop: 30,
  },
  questionContainerLight: {
    fontFamily: fonts.base,
    backgroundColor: colors.primary,
    borderRadius: 20,
    maxWidth: 490,
    minHeight: 230,
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
  },
  questionLight: {
    fontFamily: fonts.base,
    color: colors.white,
    fontWeight: 600,
    padding: 10,
    maxWidth: 250,
    display: 'block',
    margin: 'auto',
  },
  questionVectorLight: {
    paddingTop: 50,
  },
});

export default styles;
