import React from 'react';
import PropTypes from 'prop-types';

// Components
import notFound from '../../images/not_found.svg';

// Styles
import styles from './styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Error
 */
class Error extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes} = this.props;

    return (
      <div className={classes.background}>
        <div className={classes.imageContainer}>
          <img
            className={classes.pageNotFoundImage}
            alt="file not found"
            src={notFound}
          />
          <p className={classes.pageNotFound}>Page not found</p>
          <a href="https://brandonfitzwater.com/" className={classes.link}>
            <p className={classes.linkText}>GO HOME</p>
          </a>
        </div>
      </div>
    );
  }
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Error);
