import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  heroBackgroundLight: {
    backgroundColor: colors.white,
    paddingTop: 70,
    paddingBottom: 0,
  },
  heroHeadlineLight: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    display: 'block',
    margin: 'auto',
    marginLeft: 20,
    marginRight: 20,
  },
  heroMissionLight: {
    marginTop: 10,
    marginBottom: 50,
    maxWidth: 540,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: '#002B2B',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },
});

export default styles;
