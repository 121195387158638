import {
  SET_THEME_MODE,
} from '../reducers/theme';

/**
 * Sets theme of site to dark or light
 * @param {boolean} enableDarkMode
 * @return {object} pure js object
 */
export function setThemeMode(enableDarkMode) {
  return {
    type: SET_THEME_MODE,
    payload: enableDarkMode,
  };
}

