import Home from '../pages/home';
import About from '../pages/about';
import Blog from '../pages/blog';
import Faq from '../pages/faq';
import Intro from '../pages/posts/intro';
import IterativeLearning from '../pages/posts/iterative_learning';
import LinearRegression from '../pages/posts/linear_regression';
import LossAndTraining from '../pages/posts/loss';

const routes = [

  {
    path: '/about',
    component: About,
  },
  {
    path: '/blog',
    component: Blog,
  },
  {
    path: '/blog/introduction',
    component: Intro,
  },
  {
    path: '/blog/linear-regression',
    component: LinearRegression,
  },
  {
    path: '/blog/loss',
    component: LossAndTraining,
  },
  {
    path: '/blog/iterative-learning',
    component: IterativeLearning,
  },
  {
    path: '/faq',
    component: Faq,
  },
  {
    path: '/home',
    component: Home,
  },
  // This sets the default route!
  {redirect: true, path: '/', to: '/home'},
];

export default routes;
