/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import reactSvg from '../../images/react.svg';
import reduxSvg from '../../images/redux.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * WebMobile
 */
class WebMobile extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode ? classes.flexDark : classes.flexLight
        }
      >
        <Grid container className={classes.mobileWebContainer}>
          <Grid className={classes.mobileContainer} item xs={12} sm={6} md={6}>
            <div
              className={
                mode.theme.enableDarkMode
                  ? classes.mobileCardDark
                  : classes.mobileCardLight
              }
            >
              <Grid container spacing={24}>
                <Grid
                  className={classes.mobileContainer}
                  item
                  xs={2}
                  sm={2}
                  md={6}
                >
                  <h1
                    className={
                      mode.theme.enableDarkMode
                        ? classes.mobileTitleDark
                        : classes.mobileTitleLight
                    }
                  >
                    Mobile
                  </h1>
                </Grid>
                <img
                  alt="redux logo"
                  className={
                    mode.theme.enableDarkMode
                      ? classes.reactReduxLogoDark
                      : classes.reactReduxLogoLight
                  }
                  src={reduxSvg}
                />
              </Grid>
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.mobileTextDark
                      : classes.mobileTextLight
                  }
                >
                  iOS Development
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.mobileDescriptionDark
                      : classes.mobileDescriptionLight
                  }
                >
                  Custom platform solutions across iPhone, iPad, Apple Watch,
                  and Apple TV.
                </p>
              </div>
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.mobileTextDark
                      : classes.mobileTextLight
                  }
                >
                  Android Development
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.mobileDescriptionDark
                      : classes.mobileDescriptionLight
                  }
                >
                  Building unique Android experiences since the early days of
                  Nougat.
                </p>
              </div>
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.mobileTextDark
                      : classes.mobileTextLight
                  }
                >
                  Cross Platform
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.mobileDescriptionDark
                      : classes.mobileDescriptionLight
                  }
                >
                  Creating complex implementations without sacrificing speed and
                  native feel.
                </p>
              </div>
            </div>
          </Grid>
          <Grid className={classes.webContainer} item xs={12} sm={6} md={6}>
            <div
              className={
                mode.theme.enableDarkMode
                  ? classes.webCardDark
                  : classes.webCardLight
              }
            >
              <Grid container spacing={24}>
                <Grid
                  className={classes.webContainer}
                  item
                  xs={2}
                  sm={2}
                  md={6}
                >
                  <h1
                    className={
                      mode.theme.enableDarkMode
                        ? classes.webTitleDark
                        : classes.webTitleLight
                    }
                  >
                    Web
                  </h1>
                </Grid>
                <img
                  alt="react logo"
                  className={
                    mode.theme.enableDarkMode
                      ? classes.reactReduxLogoDark
                      : classes.reactReduxLogoLight
                  }
                  src={reactSvg}
                />
              </Grid>
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.webTextDark
                      : classes.webTextLight
                  }
                >
                  React
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.webDescriptionDark
                      : classes.webDescriptionLight
                  }
                >
                  Expertise deploying large-scale React and React Native
                  applications.
                </p>
              </div>
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.webTextDark
                      : classes.webTextLight
                  }
                >
                  Backend Engineering
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.webDescriptionDark
                      : classes.webDescriptionLight
                  }
                >
                  The best end-to-end experiences require a robust backend
                  foundation.
                </p>
              </div>
              <div>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.webTextDark
                      : classes.webTextLight
                  }
                >
                  Web Development
                </p>
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.webDescriptionDark
                      : classes.webDescriptionLight
                  }
                >
                  Web solutions that are flexible and perform efficiently across
                  all browsers and devices.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

WebMobile.propTypes = {
  classes: PropTypes.object,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles)(WebMobile);
