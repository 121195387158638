import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  serviceBackgroundLight: {
    backgroundColor: colors.primary,
  },
  serviceTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.white,
    marginBottom: 0,
    textAlign: 'center',
  },
  serviceSubTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.white,
    minWidth: 300,
    maxWidth: 450,
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
  },
  serviceCardLight: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    minWidth: 260,
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
  },
  serviceTextLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.primary,
    fontSize: 18,
  },
  serviceDescriptionLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#9396B9',
    fontSize: 14,
    marginTop: 60,
    marginBottom: 60,
  },
  vectorBackgroundLight: {
    marginTop: 20,
    borderRadius: 125,
    backgroundColor: '#F3F7FE',
    height: 250,
    width: 250,
    display: 'block',
    margin: 'auto',
    marginBottom: 60,
  },
});

export default styles;
