const styles = (theme) => ({
  textContainer: {
    marginBottom: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  bitzLogoContainer: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 'auto',
    marginLeft: -10,
  },
  addressLink: {
    color: '#5f6368',
    marginTop: 20,
    marginRight: 40,
  },
  bitzRocket: {
    width: 50,
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  noPaddingBottom: {
    paddingBottom: '0 !important',
  },
  spaceships: {
    width: '100%',
  },
  mobileLinksContainer: {
    display: 'block',
    margin: 'auto',
  },
  mobileGridContainer: {
    padding: '0px !important',
    width: '100%',
  },
  noPadding: {
    padding: '0px !important',
  },
});

export default styles;
