/* eslint-disable max-len */
import React from 'react';
import NavBar from '../../components/app_bar/appbar';
import Header from '../../components/posts/header/header';
import Body from '../../components/posts/body/body';
import Introduction from '../../components/introduction/intro';
import Footer from '../../components/footer/home/footer';

// Header info
const date = 'May 17, 2019';
const type = 'Developer';
const title = 'Introduction';
const author = 'Brandon Fitzwater';

/**
 * Intro
 */
class Intro extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const article = [
      {
        header: 'Welcome',
        message: 'If you’ve stumbled across this post I would first like to say thank you for visiting and welcome to the introduction on a series of mini blogs on machine learning . In these series I will demonstrate machine learning in a beginner friendly fashion.',
      },
      {
        header: 'Prerequisites',
        message: 'While these blog posts do not require prior experience in machine learning, you will need to understand some basic math and programming concepts.',
      },
      {
        header: 'End Goal',
        message: 'My mission is to not only provide a quality overview of each topic, but to help those who might not know where to start begin to dabble in a particular topic. Just to make you aware, I too will be embarking on this journey as a complete newbie.',
      },
    ];

    return (
      <div>
        <NavBar mode={this.props} />
        <Header
          mode={this.props}
          date={date}
          type={type}
          title={title}
          author={author}
        />
        <Introduction mode={this.props} />
        <Body mode={this.props} article={article} />
        <Footer mode={this.props} />
      </div>
    );
  }
}

export default Intro;
