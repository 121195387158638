/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import HomeContainer from '../containers/home';
import {Helmet} from 'react-helmet';

const styles = (theme) => ({
  home: {
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      overflow: 'visible',
    },
  },
});

/**
 * Home
 */
class Home extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes} = this.props;

    return (
      <div id="home" className={classes.home}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bitz LLC | Web and Mobile Company in Richmond Virginia</title>
          <link rel="canonical" href="https://brandonfitzwater.com/" />
          <meta
            name="description"
            content="Richmond Virginia's leading web and mobile application development company that crafts inspiring solutions to technical inquiries."
          />
          <meta
            name="keywords"
            content="enterprise apps, web development, richmond web developer, richmond freelance designs, richmond freelance websites, mobile development, IT help, bitz, bitz llc, brandon fitzwater development, react websites, ios development, android development, react native apps, richmond, virginia"
          />
        </Helmet>
        <HomeContainer />
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);

