/* eslint-disable max-len */
import {fonts, colors} from '../../../theme';
import backgroundPrimaryDark from '../../../images/lines_circles_dark.svg';

const styles = (theme) => ({
  backgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    paddingBottom: 100,
  },
  backgroundHeroDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
  },
  bodyDark: {
    textAlign: 'left',
    color: colors.darkThemeWhiteText,
    fontSize: 18,
    marginTop: 30,
    marginBottom: 0,
    fontFamily: fonts.base,
    marginLeft: 100,
    marginRight: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
      marginRight: 25,
    },
  },
  bulletDark: {
    textAlign: 'left',
    color: colors.darkThemeWhiteText,
    fontSize: 18,
    marginTop: 10,
    marginBottom: 5,
    fontFamily: fonts.base,
    marginLeft: 150,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 50,
    },
  },
  cardImageDarkGrey: {
    'backgroundImage': `url(${backgroundPrimaryDark})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundColor': colors.darkThemeMediumGreyBackground,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 800,
      height: 400,
    },
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
    'display': 'block',
    'margin': 'auto',
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
    'margin-bottom': 20,
  },
  definitionTextDark: {
    'color': '#A5D6A7',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  figureTextDark: {
    color: colors.darkPrimary,
    fontFamily: fonts.base,
    fontWeight: 600,
  },
  formulaDark: {
    marginTop: 30,
    fontStyle: 'italic',
    color: colors.darkPrimary,
    textAlign: 'center',
  },
  formulaTextDark: {
    color: colors.darkPrimary,
  },
  linkDark: {
    color: colors.darkPrimary,
    textDecoration: 'none',
  },
  secondaryHeaderDark: {
    textAlign: 'left',
    color: colors.darkPrimary,
    fontSize: 36,
    marginTop: 50,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
    },
  },
  titleDark: {
    textAlign: 'left',
    color: colors.darkPrimary,
    fontSize: 36,
    marginTop: 0,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
    },
  },
  unorderedListDark: {
    textAlign: 'left',
    color: colors.darkThemeWhiteText,
    backgroundColor: colors.darkThemeLightGreyBackground,
    padding: 20,
    borderRadius: 8,
    fontSize: 18,
    marginTop: 30,
    marginBottom: 0,
    fontFamily: fonts.base,
    marginLeft: 100,
    marginRight: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 25,
      marginRight: 25,
    },
  },
});

export default styles;
