import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import sloganDark from '../../images/slogan_hero_dark.svg';
import sloganLight from '../../images/slogan_hero_light.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Slogan
 */
class Slogan extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.backgroundDark
            : classes.backgroundLight
        }
      >
        <Grid container spacing={24} className={classes.gridWrapper}>
          <Grid item xs={12} sm={12}>
            <div>
              <img
                className={classes.sloganHero}
                alt="user interactive with mobile application"
                src={mode.theme.enableDarkMode ? sloganDark : sloganLight}
              />
              <h1
                className={
                  mode.theme.enableDarkMode
                    ? classes.headerDark
                    : classes.headerLight
                }
              >
                One Developer. Infinite Possibilities.
              </h1>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Slogan.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Slogan);
