import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

// Images
import answer from '../../images/answer.svg';
import answerDark from '../../images/answer_dark.svg';
import question from '../../images/question.svg';
import questionDark from '../../images/question_dark.svg';

/**
 * Qa
 */
class Qa extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, questions, mode} = this.props;
    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.backgroundDark
            : classes.backgroundLight
        }
      >
        {questions.map((object, index) => (
          <Grid
            key={index.toString()}
            container
            spacing={24}
            className={classes.grid}
          >
            <Grid item xs={12} sm={12} md={6}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.questionContainerDark
                    : classes.questionContainerLight
                }
              >
                <img
                  className={
                    mode.theme.enableDarkMode
                      ? classes.questionVectorDark
                      : classes.questionVectorLight
                  }
                  alt={
                    mode.theme.enableDarkMode
                      ? 'chat bubble dark theme'
                      : 'chat bubble light theme'
                  }
                  src={mode.theme.enableDarkMode ? questionDark : question}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.questionDark
                      : classes.questionLight
                  }
                >
                  {object.question}
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.answerContainerDark
                    : classes.answerContainerLight
                }
              >
                <img
                  className={
                    mode.theme.enableDarkMode
                      ? classes.answerVectorDark
                      : classes.answerVectorLight
                  }
                  alt={
                    mode.theme.enableDarkMode
                      ? 'person answering question dark theme'
                      : 'person answering question bubble light theme'
                  }
                  src={mode.theme.enableDarkMode ? answerDark : answer}
                />
                <p
                  className={
                    mode.theme.enableDarkMode
                      ? classes.answerDark
                      : classes.answerLight
                  }
                >
                  {object.answer}
                </p>
              </div>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }
}

Qa.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles)(Qa);
