const styles = (theme) => ({
  gridWrapper: {
    width: '85%',
    margin: '0px auto 0',
    paddingTop: 30,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 10,
    },
    paddingBottom: 40,
    position: 'relative',
    textAlign: 'center',
  },
  sloganHero: {
    width: '100%',
  },
});

export default styles;
