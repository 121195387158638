const styles = (theme) => ({
  background: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#6067f1',
    position: 'absolute',
    display: 'block',
    margin: 'auto',
  },
  imageContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 100,
      display: 'block',
      margin: 'auto',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 200,
      display: 'block',
      margin: 'auto',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '100%',
    },
  },
  link: {
    'textDecoration': 'none',
    'color': '#FFF',
    'fontFamily': 'Poppins',
    'fontSize': 12,
    'display': 'block',
    'margin': 'auto',
    'marginTop': 30,
    'height': 45,
    'width': 120,
    'backgroundColor': '#565dd9',
    '&:hover': {
      backgroundColor: '#5258cd',
    },
    'borderRadius': 30,
  },
  linkText: {
    paddingTop: 13,
    color: '#FFF',
  },
  pageNotFound: {
    'color': '#bdc3f6',
    'fontFamily': 'Poppins',
    'fontSize': 30,
    'display': 'block',
    'margin': 'auto',
    'marginTop': 30,
    '&:hover': {
      color: '#bdc3f6 !important',
    },
  },
  pageNotFoundImage: {
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
    [theme.breakpoints.up('sm')]: {
      height: 200,
    },
  },
});

export default styles;
