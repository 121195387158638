/* eslint-disable max-len */
import React from 'react';
import NavBar from '../components/app_bar/appbar';
import Hero from '../components/hero/home/hero';
import WebMobile from '../components/web_mobile/webMobile';
import InStores from '../components/in_stores/stores';
import Services from '../components/services/services';
import Process from '../components/process/process';
import Projects from '../components/projects/projects';
import Footer from '../components/footer/home/footer';

/**
 * Home
 */
class Home extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <NavBar mode={this.props} />
        <Hero mode={this.props} />
        <WebMobile mode={this.props} />
        <InStores mode={this.props} />
        <Services mode={this.props} />
        <Process mode={this.props} />
        <Projects mode={this.props} />
        <Footer mode={this.props} />
      </div>
    );
  }
}

export default Home;
