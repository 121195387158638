import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  backgroundLight: {
    backgroundColor: colors.primary,
    paddingBottom: 40,
    paddingTop: 40,
  },
  titleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.white,
  },
  subTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 18,
    color: colors.white,
  },
  descriptionLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 14,
    color: colors.lightThemeLightGrey,
    width: '95%',
  },
});

export default styles;
