import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import goDukesLight from '../../images/go_dukes_light.svg';
import goDukesDark from '../../images/go_dukes_dark.svg';
import undergradLight from '../../images/undergrad_light.svg';
import undergradDark from '../../images/undergrad_dark.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Undergrad
 */
class Undergrad extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.backgroundDark
            : classes.backgroundLight
        }
      >
        <Grid container className={classes.processContainer} spacing={24}>
          <Grid item xs={12} sm={12} className={classes.noPaddingBottom}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={24} className={classes.container}>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <img
                      className={classes.undergradImage}
                      alt="individual working out (healthy habits)"
                      src={
                        mode.theme.enableDarkMode
                          ? undergradDark
                          : undergradLight
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.titleDark
                          : classes.titleLight
                      }
                    >
                      Undergrad - James Madison University
                    </h1>
                  </div>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.subTitleDark
                            : classes.subTitleLight
                        }
                      >
                        Mathematics
                      </p>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.descriptionDark
                            : classes.descriptionLight
                        }
                      >
                        I got my degree in math because I thought it would be a
                        good general degree.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.subTitleDark
                            : classes.subTitleLight
                        }
                      >
                        Robotics
                      </p>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.descriptionDark
                            : classes.descriptionLight
                        }
                      >
                        Honestly, if school offered this degree at the time, I
                        would probably have my BS in Robotics…
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.subTitleDark
                            : classes.subTitleLight
                        }
                      >
                        Computer Science
                      </p>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.descriptionDark
                            : classes.descriptionLight
                        }
                      >
                        Programming never came naturally, but I really took an
                        interest during college.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.subTitleDark
                            : classes.subTitleLight
                        }
                      >
                        Physics
                      </p>
                      <p
                        className={
                          mode.theme.enableDarkMode
                            ? classes.descriptionDark
                            : classes.descriptionLight
                        }
                      >
                        I just thought this was a good framework for thinking.
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <div className={classes.goDukes}>
                          <img
                            className={classes.vectorImage}
                            alt="individual working out (healthy habits)"
                            src={
                              mode.theme.enableDarkMode
                                ? goDukesDark
                                : goDukesLight
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Undergrad.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(commonStyles, darkStyles, lightStyles);

export default withStyles(combinedStyles, {withTheme: true})(Undergrad);
