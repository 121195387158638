/* eslint-disable no-unused-vars */
import Home from '../pages/app';
import Error from '../pages/error';

const indexRoutes = [
  {
    path: '/error',
    component: Error,
  },
  {
    path: '/',
    component: Home,
  },
];

export default indexRoutes;
