const styles = (theme) => ({
  designImage: {
    height: 80,
    marginLeft: 22,
    marginTop: 37,
  },
  developImage: {
    height: 70,
    marginLeft: 10,
    marginTop: 37,
  },
  interfaceImage: {
    height: 75,
    marginLeft: 20,
    marginTop: 30,
  },
  releaseImage: {
    height: 80,
    marginLeft: 15,
    marginTop: 35,
  },
  testImage: {
    height: 95,
    marginLeft: 20,
    marginTop: 37,
  },
  processContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  noPaddingTop: {
    paddingTop: '0px !important',
  },
  noPaddingBottom: {
    paddingBottom: '0px !important',
  },
});

export default styles;
