/* eslint-disable max-len */
import React from 'react';
import IterativeLearningContainer from '../../containers/posts/iterative_learning';
import {Helmet} from 'react-helmet';

/**
 * Iterative Learning
 */
class IterativeLearning extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reducing Loss - Iterative Learning</title>
          <link
            rel="canonical"
            href="http://brandonfitzwater.com/blog/iterative-learning"
          />
          <meta
            name="description"
            content="Iterative machine learning is a trial-and-error process that machine learning algorithms use to train a model."
          />
          <meta
            name="keywords"
            content="iterative learning, machine learning, reducing loss, artifical intelligence, reduction techniques, beginner machine learning tutorial"
          />
        </Helmet>
        <IterativeLearningContainer />
      </div>
    );
  }
}

export default IterativeLearning;
