/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import AppBar from '@material-ui/core/AppBar';
import CancelIcon from '@material-ui/icons/Cancel';
import SunIcon from '@material-ui/icons/WbSunny';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import compose from 'recompose/compose';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoonIcon from '@material-ui/icons/Brightness3';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import {NavLink} from 'react-router-dom';

// Images
import logoLight from '../../images/bitz_logo_light.svg';
import logoDark from '../../images/bitz_logo_purple.svg';

// Styles
import styles from './styles';
import {withStyles} from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import combineStyles from '../../utilities/combine_styles';
import darkAppBarStyles from '../../theme/app_bar/dark/styles';
import lightAppBarStyles from '../../theme/app_bar/light/styles';

/**
 * NavBar
 */
class NavBar extends React.Component {
  state = {
    anchorElCompany: null,
    enableDarkTheme: false,
    open: false,
    openCompany: false,
    openMobile: false,
    placementCompany: null,
  };

  /**
   * @Function handleCompanyClick
   * @Purpose opens the popper menu when the uer clicks the company button
   * @param {String} placementCompany - string where popper opens
   * @param {Object} event - event object
   * @return {Object}
   */
  handleCompanyClick = (placementCompany) => (event) => {
    const {currentTarget} = event;
    this.setState((state) => ({
      anchorElCompany: currentTarget,
      openCompany: true,
      placementCompany,
    }));
  };

  /**
   * @Function handleMobileClick
   * @Purpose opens the popper menu when the uer clicks the mobile button
   * @param {String} placementMobile - string where popper opens
   * @param {Object} event - event object
   * @return {Object}
   */
  handleMobileClick = (placementMobile) => (event) => {
    const {currentTarget} = event;
    this.setState((state) => ({
      anchorElMobile: currentTarget,
      openMobile:
        state.placementMobile !== placementMobile || !state.openMobile,
      placementMobile,
      openCompany: false,
    }));
  };

  /**
   * @Function closeMenu
   * @Purpose closes mobile popover menu
   */
  closeMenu = () => {
    this.setState({
      openMobile: false,
      openCompany: false,
    });
  };

  /**
   * @Function sendEmail
   * @Purpose opens users email populating subject
   */
  sendEmail = () => {
    window.location.href =
      'mailto:brandonfitzh2o@gmail.com?subject=Bitz LLC Inquiry';
  };

  /**
   * @Function goToGithub
   * @Purpose takes user to my github
   */
  goToGithub = () => {
    window.open('https://github.com/iM-GeeKy');
  };

  /**
   * @Function goToTwitter
   * @Purpose takes user to my twitter
   */
  goToTwitter = () => {
    window.open('https://twitter.com/im_geeky');
  };

  /**
   * @Function goToLinkedIn
   * @Purpose takes user to my linkedIn
   */
  goToLinkedIn = () => {
    window.open('https://www.linkedin.com/in/brandon-fitzwater-28736b9a/');
  };

  /**
   * @Function goToFacebook
   * @Purpose takes user to my facebook
   */
  goToFacebook = () => {
    window.open('https://www.facebook.com/brandon.fitzwater.104');
  };

  /**
   * @Function goToAngelList
   * @Purpose takes user to my angellist profile
   */
  goToAngelList = () => {
    window.open('https://angel.co/brandon-fitzwater');
  };

  /**
   * @Function handleThemeChange
   * @Purpose toggles dark and light theme
   * @param {object} event
   */
  handleThemeChange = (event) => {
    this.props.mode.setThemeMode(event.target.checked);
    this.setState({
      enableDarkTheme: event.target.checked,
    });
  };

  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;
    const {
      anchorElCompany,
      anchorElMobile,
      openCompany,
      openMobile,
      placementCompany,
      placementMobile,
    } = this.state;

    const idCompany = openCompany ? 'simple-popper' : null;
    const idMobile = openMobile ? 'simple-popper' : null;

    return (
      <div className={classes.root}>
        <AppBar
          className={
            mode.theme.enableDarkMode ? classes.appbarDark : classes.appbarLight
          }
          position="static"
        >
          <Toolbar className={classes.toolbar}>
            <NavLink to={'/'} className={classes.bitzRocketLink}>
              <img
                className={classes.bitzRocket}
                alt="Bitz LLC rocket logo"
                src={mode.theme.enableDarkMode ? logoDark : logoLight}
              />
              <p
                className={
                  mode.theme.enableDarkMode
                    ? classes.companyNameDark
                    : classes.companyNameLight
                }
              >
                Bitz LLC
              </p>
            </NavLink>
            <Hidden xsDown implementation="css">
              <SunIcon className={classes.darkThemeIcons} />
              <Switch
                checked={mode.theme.enableDarkMode}
                onChange={this.handleThemeChange}
                classes={{
                  switchBase: mode.theme.enableDarkMode
                    ? classes.switchDark
                    : classes.switchLight,
                  checked: mode.theme.enableDarkMode
                    ? classes.switchCheckedDark
                    : classes.switchCheckedLight,
                  bar: mode.theme.enableDarkMode
                    ? classes.switchBarDark
                    : classes.switchBarLight,
                }}
              />
              <MoonIcon className={classes.darkThemeIcons} />
              <Button
                disabled={this.state.openCompany}
                className={
                  mode.theme.enableDarkMode
                    ? classes.buttonTextDark
                    : classes.buttonTextLight
                }
                onClick={this.handleCompanyClick('bottom-end')}
              >
                Menu
              </Button>
              <Popper
                id={idCompany}
                open={this.props.width !== 'xs' && (openCompany || openMobile)}
                anchorEl={anchorElCompany}
                placement={placementCompany}
                transition
              >
                {({TransitionProps}) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className={
                        mode.theme.enableDarkMode
                          ? classes.popperDesktopDark
                          : classes.popperDesktopLight
                      }
                    >
                      <MenuList role="menu">
                        <Grid container spacing={24}>
                          <Grid
                            item
                            xs={12}
                            className={classes.popperColumnTitle}
                          >
                            <MenuItem className={classes.popperIcon}>
                              <p className={classes.mobilePopperTitleText}>
                                Company
                              </p>
                              <IconButton onClick={this.closeMenu}>
                                <CancelIcon className={classes.cancelIcon} />
                              </IconButton>
                            </MenuItem>
                          </Grid>
                          <Grid item xs={6} className={classes.popperColumn}>
                            <NavLink
                              to={'/about'}
                              className={classes.noUnderline}
                            >
                              <MenuItem className={classes.popperIcon}>
                                <p className={classes.popperText}>About</p>
                              </MenuItem>
                            </NavLink>
                          </Grid>
                          <Grid item xs={6} className={classes.popperColumn}>
                            <MenuItem
                              className={classes.popperIcon}
                              onClick={this.sendEmail}
                            >
                              <p className={classes.popperText}>Contact</p>
                            </MenuItem>
                          </Grid>
                          <Grid item xs={6} className={classes.popperColumn}>
                            <NavLink
                              to={'/faq'}
                              className={classes.noUnderline}
                            >
                              <MenuItem className={classes.popperIcon}>
                                <p className={classes.popperText}>FAQ</p>
                              </MenuItem>
                            </NavLink>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.popperColumnNoPadding}
                          >
                            <MenuItem className={classes.popperIcon}>
                              <p className={classes.mobilePopperTitleText}>
                                Community
                              </p>
                            </MenuItem>
                          </Grid>
                          <Grid item xs={6} className={classes.popperColumn}>
                            <MenuItem
                              className={classes.popperIcon}
                              onClick={this.goToGithub}
                            >
                              <p className={classes.popperText}>Github</p>
                            </MenuItem>
                            <MenuItem
                              className={classes.popperIcon}
                              onClick={this.goToTwitter}
                            >
                              <p className={classes.popperText}>Twitter</p>
                            </MenuItem>
                            <MenuItem
                              className={classes.popperIcon}
                              onClick={this.goToFacebook}
                            >
                              <p className={classes.popperText}>Facebook</p>
                            </MenuItem>
                          </Grid>
                          <Grid item xs={6} className={classes.popperColumn}>
                            <MenuItem
                              className={classes.popperIcon}
                              onClick={this.goToLinkedIn}
                            >
                              <p className={classes.popperText}>LinkedIn</p>
                            </MenuItem>
                            <MenuItem
                              className={classes.popperIcon}
                              onClick={this.goToAngelList}
                            >
                              <p className={classes.popperText}>Angellist</p>
                            </MenuItem>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.popperColumnNoPadding}
                          >
                            <MenuItem className={classes.popperIcon}>
                              <p className={classes.mobilePopperTitleText}>
                                Developer
                              </p>
                            </MenuItem>
                          </Grid>
                          <Grid item xs={6} className={classes.popperColumn}>
                            <NavLink
                              onClick={this.closeMenu}
                              to={'/blog'}
                              className={classes.noUnderline}
                            >
                              <MenuItem className={classes.popperIcon}>
                                <p className={classes.popperText}>Blog</p>
                              </MenuItem>
                            </NavLink>
                          </Grid>
                        </Grid>
                      </MenuList>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Hidden>
            <Hidden smUp implementation="css">
              <SunIcon className={classes.darkThemeIcons} />
              <Switch
                checked={mode.theme.enableDarkMode}
                onChange={this.handleThemeChange}
                classes={{
                  switchBase: mode.theme.enableDarkMode
                    ? classes.switchDark
                    : classes.switchLight,
                  checked: mode.theme.enableDarkMode
                    ? classes.switchCheckedDark
                    : classes.switchCheckedLight,
                  bar: mode.theme.enableDarkMode
                    ? classes.switchBarDark
                    : classes.switchBarLight,
                }}
              />
              <MoonIcon className={classes.darkThemeIcons} />
              <IconButton
                onClick={this.handleMobileClick('bottom-end')}
                className={
                  mode.theme.enableDarkMode
                    ? classes.menuButtonDark
                    : classes.menuButtonLight
                }
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
              <Popper
                id={idMobile}
                open={
                  (openMobile && this.props.width === 'xs') ||
                  (this.props.width === 'xs' && openCompany)
                }
                anchorEl={anchorElMobile}
                placement={placementMobile}
                transition
                className={classes.mobilePopperWrapper}
              >
                {({TransitionProps}) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className={
                        mode.theme.enableDarkMode
                          ? classes.mobilePopperDark
                          : classes.mobilePopperLight
                      }
                    >
                      <ClickAwayListener onClickAway={this.closeMenu}>
                        <MenuList role="menu">
                          <Grid container spacing={24}>
                            <Grid
                              item
                              xs={12}
                              className={classes.popperColumnTitle}
                            >
                              <MenuItem className={classes.popperIcon}>
                                <p className={classes.mobilePopperTitleText}>
                                  Company
                                </p>
                                <IconButton onClick={this.closeMenu}>
                                  <CancelIcon className={classes.cancelIcon} />
                                </IconButton>
                              </MenuItem>
                            </Grid>
                            <Grid item xs={6} className={classes.popperColumn}>
                              <NavLink
                                onClick={this.closeMenu}
                                to={'/about'}
                                className={classes.noUnderline}
                              >
                                <MenuItem className={classes.popperIcon}>
                                  <p className={classes.mobilePopperText}>
                                    About
                                  </p>
                                </MenuItem>
                              </NavLink>
                            </Grid>
                            <Grid item xs={6} className={classes.popperColumn}>
                              <MenuItem
                                className={classes.popperIcon}
                                onClick={this.sendEmail}
                              >
                                <p className={classes.mobilePopperText}>
                                  Contact
                                </p>
                              </MenuItem>
                            </Grid>
                            <Grid item xs={6} className={classes.popperColumn}>
                              <NavLink
                                onClick={this.closeMenu}
                                to={'/faq'}
                                className={classes.noUnderline}
                              >
                                <MenuItem className={classes.popperIcon}>
                                  <p className={classes.mobilePopperText}>
                                    FAQ
                                  </p>
                                </MenuItem>
                              </NavLink>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.popperColumnNoPadding}
                            >
                              <MenuItem className={classes.popperIcon}>
                                <p className={classes.mobilePopperTitleText}>
                                  Community
                                </p>
                              </MenuItem>
                            </Grid>
                            <Grid item xs={6} className={classes.popperColumn}>
                              <MenuItem
                                className={classes.popperIcon}
                                onClick={this.goToGithub}
                              >
                                <p className={classes.mobilePopperText}>
                                  Github
                                </p>
                              </MenuItem>
                              <MenuItem
                                className={classes.popperIcon}
                                onClick={this.goToTwitter}
                              >
                                <p className={classes.mobilePopperText}>
                                  Twitter
                                </p>
                              </MenuItem>
                              <MenuItem
                                className={classes.popperIcon}
                                onClick={this.goToFacebook}
                              >
                                <p className={classes.mobilePopperText}>
                                  Facebook
                                </p>
                              </MenuItem>
                            </Grid>
                            <Grid item xs={6} className={classes.popperColumn}>
                              <MenuItem
                                className={classes.popperIcon}
                                onClick={this.goToLinkedIn}
                              >
                                <p className={classes.mobilePopperText}>
                                  LinkedIn
                                </p>
                              </MenuItem>
                              <MenuItem
                                className={classes.popperIcon}
                                onClick={this.goToAngelList}
                              >
                                <p className={classes.mobilePopperText}>
                                  Angellist
                                </p>
                              </MenuItem>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.popperColumnNoPadding}
                            >
                              <MenuItem className={classes.popperIcon}>
                                <p className={classes.mobilePopperTitleText}>
                                  Developer
                                </p>
                              </MenuItem>
                            </Grid>
                            <Grid item xs={6} className={classes.popperColumn}>
                              <NavLink
                                onClick={this.closeMenu}
                                to={'/blog'}
                                className={classes.noUnderline}
                              >
                                <MenuItem className={classes.popperIcon}>
                                  <p className={classes.popperText}>Blog</p>
                                </MenuItem>
                              </NavLink>
                            </Grid>
                          </Grid>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Hidden>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

const combinedStyles = combineStyles(styles, lightAppBarStyles, darkAppBarStyles);

export default compose(
    withStyles(combinedStyles),
    withWidth()
)(NavBar);
