import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  authorLight: {
    textAlign: 'center',
    color: '#5F6A96',
    fontSize: 15,
    marginTop: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    paddingBottom: 30,
    marginBottom: 0,
  },
  dateStampLight: {
    color: '#9DA5C7',
    fontSize: 14,
    fontFamily: fonts.base,
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 100,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
    },
  },
  headerBackgroundLight: {
    backgroundColor: colors.white,
  },
  titleLight: {
    textAlign: 'center',
    color: '#5F6A96',
    fontSize: 50,
    marginTop: 0,
    marginBottom: 0,
    fontFamily: fonts.base,
    fontWeight: 600,
    maxWidth: 710,
    display: 'block',
    margin: 'auto',
    padding: 10,
  },
});

export default styles;
