/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';
import {NavLink} from 'react-router-dom';

// Images
import logoDark from '../../../images/bitz_logo_green.svg';
import logoPurple from '../../../images/bitz_logo_purple.svg';
import logoLight from '../../../images/bitz_logo_alt.svg';

// Styles
import combineStyles from '../../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Overview
 */
class Overview extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.wrapperDark
            : classes.wrapperLight
        }
      >
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div>
              <NavLink
                to={'/blog/linear-regression'}
                className={classes.noUnderline}
              >
                <div className={classes.card}>
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardImageDarkGrey
                        : classes.cardImagePrimary
                    }
                  >
                    <img
                      className={classes.bitzRocket}
                      alt="Bitz LLC rocket logo"
                      src={mode.theme.enableDarkMode ? logoDark : logoLight}
                    />
                    <p className={classes.headline}>Machine Learning</p>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.titleGreen
                          : classes.title
                      }
                    >
                      Linear Regression
                    </p>
                    <p className={classes.shortDescription}>
                      Grey Kangaroos
                    </p>
                  </div>

                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardTextDark
                        : classes.cardTextLight
                    }
                  >
                    Linear Regression
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardDescriptionDark
                        : classes.cardDescriptionLight
                    }
                  >
                    Linear regression is a method for finding the straight
                    line that best fits a set of points. In this example, we
                    will be exploring the linear relationsship between the
                    grey kangaroo's nose width (mm) and the nose length
                    (mm). Are you ready? Let's hop to it...
                  </p>
                </div>
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div>
              <NavLink to={'/blog/loss'} className={classes.noUnderline}>
                <div className={classes.card}>
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardImageDarkGrey
                        : classes.cardImageAlt
                    }
                  >
                    <img
                      className={classes.bitzRocket}
                      alt="Bitz LLC rocket logo"
                      src={
                        mode.theme.enableDarkMode ? logoPurple : logoLight
                      }
                    />
                    <p className={classes.headline}>Machine Learning</p>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.titlePurple
                          : classes.title
                      }
                    >
                      Loss and Predictions
                    </p>
                    <p className={classes.shortDescription}>
                      Squared Loss and Mean Square Error
                    </p>
                  </div>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardTextDark
                        : classes.cardTextLight
                    }
                  >
                    Loss and Predictions
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardDescriptionDark
                        : classes.cardDescriptionLight
                    }
                  >
                    Loss is a measure of how bad a model is and to get this
                    value a model must first have a loss function. In this
                    article, we will examine a popular loss function,
                    squared loss and look at mean square error (MSE), which
                    is the average square loss over the entire dataset.
                  </p>
                </div>
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div>
              <NavLink to={'/blog/iterative-learning'} className={classes.noUnderline}>
                <div className={classes.card}>
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardImageDarkGrey
                        : classes.cardImagePrimary
                    }
                  >
                    <img
                      className={classes.bitzRocket}
                      alt="Bitz LLC rocket logo"
                      src={mode.theme.enableDarkMode ? logoDark : logoLight}
                    />
                    <p className={classes.headline}>Machine Learning</p>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.titleGreen
                          : classes.title
                      }
                    >
                      Loss Reduction
                    </p>
                    <p className={classes.shortDescription}>
                      Iterative Learning
                    </p>
                  </div>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardTextDark
                        : classes.cardTextLight
                    }
                  >
                    Iterative Learning
                  </p>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.cardDescriptionDark
                        : classes.cardDescriptionLight
                    }
                  >
                    Iterative machine learning is a trial-and-error process
                    that machine learning algorithms use to train a model.
                    The way this works is a model is trained by starting
                    with an initial guess for the weights and bias and
                    iteratively adjusting those guesses until learning the
                    weights and bias with the lowest loss.
                  </p>
                </div>
              </NavLink>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Overview.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Overview);
