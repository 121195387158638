const styles = (theme) => ({
  experienceImage: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 225,
    },
  },
  textPanel: {
    width: 300,
    display: 'block',
    marginTop: 120,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 225,
      marginTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      width: 225,
      marginTop: 30,
    },
  },
});

export default styles;
