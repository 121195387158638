/* eslint-disable max-len */
import {fonts, colors} from '../../../../../theme';
import backgroundPrimaryDark from '../../../../../images/lines_circles_dark.svg';

const styles = (theme) => ({
  wrapperDark: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    paddingBottom: 80,
    backgroundColor: colors.darkThemeDarkGreyBackground,
  },
  cardImageDarkGrey: {
    'backgroundImage': `url(${backgroundPrimaryDark})`,
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundColor': colors.darkThemeMediumGreyBackground,
    'maxWidth': 302,
    'height': 200,
    'borderRadius': 20,
    '&:hover': {
      '-webkit-transition': 'all .4s ease',
      'transition': 'all .4s ease',
      '-webkit-transform': 'translate3D(0,-1px,0) scale(1.02)',
      'transform': 'translate3D(0,-1px,0) scale(1.02)',
      'cursor': 'pointer',
    },
    '-webkit-transition': 'all .5s ease',
    'transition': 'all .5s ease',
  },
  cardTextDark: {
    marginTop: 50,
    textAlign: 'left',
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#A6B0D4',
    fontSize: 16,
  },
  cardDescriptionDark: {
    textAlign: 'left',
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
    fontSize: 14,
    marginBottom: 40,
  },
});

export default styles;
