/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NavBar from '../components/app_bar/appbar';
import Hero from '../components/hero/about/hero';
import Interests from '../components/interests/interests';
import Undergrad from '../components/undergrad/undergrad';
import Slogan from '../components/slogan/slogan';
import Experience from '../components/experience/experience';
import Footer from '../components/footer/about/footer';
import {Helmet} from 'react-helmet';

const styles = (theme) => ({
  about: {
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      overflow: 'visible',
    },
  },
});

/**
 * About
 */
class About extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.home}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About - Bitz LLC</title>
          <link rel="canonical" href="https://brandonfitzwater.com/about" />
          <meta
            name="description"
            content="Get to know a little more about myself and what makes me tick. You may think I spend all my day coding, which is mainly true, but I do enjoy a variety of other things outside of work."
          />
          <meta
            name="keywords"
            content="about bitz llc, about brandon fitzwater, learn about bitz, brandon fitzwater hobbies, developer interests"
          />
        </Helmet>
        <NavBar mode={this.props} />
        <Hero mode={this.props} />
        <Interests mode={this.props} />
        <Undergrad mode={this.props} />
        <Experience mode={this.props} />
        <Slogan mode={this.props} />
        <Footer mode={this.props} />
      </div>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
