import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';

// Images
import design from '../../images/design.svg';
import interfaceSvg from '../../images/interface.svg';
import develop from '../../images/develop.svg';
import test from '../../images/test.svg';
import release from '../../images/release.svg';

// Styles
import combineStyles from '../../utilities/combine_styles';
import commonStyles from './styles/common/styles';
import darkStyles from './styles/dark/styles';
import lightStyles from './styles/light/styles';
import {withStyles} from '@material-ui/core/styles';

/**
 * Process
 */
class Process extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    const {classes, mode} = this.props;

    return (
      <div
        className={
          mode.theme.enableDarkMode
            ? classes.processBackgroundDark
            : classes.processBackgroundLight
        }
      >
        <Grid container className={classes.processContainer} spacing={24}>
          <Grid item xs={12} sm={12} md={6} className={classes.noPaddingBottom}>
            <Grid item xs={12} sm={12} md={12}>
              <h1
                className={
                  mode.theme.enableDarkMode
                    ? classes.processTitleDark
                    : classes.processTitleLight
                }
              >
                The Process
              </h1>
              <p
                className={
                  mode.theme.enableDarkMode
                    ? classes.processSubTitleDark
                    : classes.processSubTitleLight
                }
              >
                The entire development procedure is generally abstracted into 5
                steps:
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.interfaceImage}
                        alt="two people interfacing with one another"
                        src={interfaceSvg}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Interface
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      Having a fluid dialog with the client ensures the work
                      meets customer expectations.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.testImage}
                        alt="group of people testing application"
                        src={test}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Test
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      Automated test scripts not only remove human error, but
                      provide a more stable product.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.noPaddingTop}>
            <div
              className={
                this.props.width === 'xs'
                  ? null
                  : mode.theme.enableDarkMode
                  ? classes.processCardDark
                  : classes.processCardLight
              }
            >
              <Grid container className={classes.processContainer} spacing={24}>
                <Grid item xs={12} sm={5} md={4}>
                  <div
                    className={
                      mode.theme.enableDarkMode
                        ? classes.vectorBackgroundDark
                        : classes.vectorBackgroundLight
                    }
                  >
                    <img
                      className={classes.designImage}
                      alt="woman getting design inspiration"
                      src={design}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={7} md={8}>
                  <h1
                    className={
                      mode.theme.enableDarkMode
                        ? classes.processCardTitleDark
                        : classes.processCardTitleLight
                    }
                  >
                    Design
                  </h1>
                  <p
                    className={
                      mode.theme.enableDarkMode
                        ? classes.processCardSubTitleDark
                        : classes.processCardSubTitleLight
                    }
                  >
                    Creating a plan gives a high level understanding of the
                    software product.
                  </p>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12} sm={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.developImage}
                        alt="developing coding on multiple monitors"
                        src={develop}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Develop
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      This part is easy, but not simple. During this phase
                      dreams become reality.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div
                className={
                  mode.theme.enableDarkMode
                    ? classes.processCardDark
                    : classes.processCardLight
                }
              >
                <Grid
                  container
                  className={classes.processContainer}
                  spacing={24}
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <div
                      className={
                        mode.theme.enableDarkMode
                          ? classes.vectorBackgroundDark
                          : classes.vectorBackgroundLight
                      }
                    >
                      <img
                        className={classes.releaseImage}
                        alt="runner finishing 1st place in race"
                        src={release}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7} md={8}>
                    <h1
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardTitleDark
                          : classes.processCardTitleLight
                      }
                    >
                      Release
                    </h1>
                    <p
                      className={
                        mode.theme.enableDarkMode
                          ? classes.processCardSubTitleDark
                          : classes.processCardSubTitleLight
                      }
                    >
                      The goal is not to provide a product you are happy with,
                      but one that will blow you away.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Process.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
    commonStyles,
    darkStyles,
    lightStyles
);

export default withStyles(combinedStyles, {withTheme: true})(Process);
