/* eslint-disable max-len */
import React from 'react';
import LossContainer from '../../containers/posts/loss';
import {Helmet} from 'react-helmet';

/**
 * Training and Loss
 */
class Loss extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Loss and Predictions - Bitz LLC</title>
          <link
            rel="canonical"
            href="http://brandonfitzwater.com/blog/loss"
          />
          <meta
            name="description"
            content="Loss is the penalty for a bad prediction. Loss is a number indicating how bad the model's prediction was on a single example."
          />
          <meta
            name="keywords"
            content="loss, machine learning, predictions, artifical intelligence, training, mean square error, squared loss"
          />
        </Helmet>
        <LossContainer />
      </div>
    );
  }
}

export default Loss;
