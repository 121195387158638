/* eslint-disable max-len */
import Loss from '../../components/machine_learning/loss/loss';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  setThemeMode,
} from '../../actions/theme';

/**
 * Utility which will help your component to fire an action
 * event(dispatching action which may cause change of application state)
 * @param {object} dispatch
 * @return {object} actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
      {
        setThemeMode: setThemeMode,
      },
      dispatch
  );
}

/**
 * is a utility which helps your component get updated
 * state(which is updated by some other components),
 *  @param {object} state
 * @return {props} object of props
 */
function mapStateToProps(state) {
  return {
    theme: state.theme,
  };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Loss);
