import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  projectsBackgroundLight: {
    backgroundColor: colors.white,
  },
  serviceTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.primary,
    marginBottom: 0,
    textAlign: 'center',
  },
  projectTitleLight: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: '#626E98',
    fontSize: 16,
  },
  projectDescriptionLight: {
    fontFamily: fonts.base,
    color: '#626E98',
    fontSize: 14,
  },
});

export default styles;
