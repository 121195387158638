import {fonts, colors} from '../../../../theme';

const styles = (theme) => ({
  storeBackgroundDark: {
    backgroundColor: colors.darkThemeMediumGreyBackground,
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      overflow: 'visible',
    },
  },
  storeTitleDark: {
    width: 300,
    fontFamily: fonts.base,
    fontWeight: 600,
    fontSize: 48,
    color: colors.darkPrimary,
    marginLeft: 40,
    marginBottom: 0,
  },
  storeSubTitleDark: {
    color: colors.darkThemeWhiteText,
    fontFamily: fonts.base,
    fontWeight: 600,
    marginLeft: 40,
    marginBottom: 75,
  },
  huntClubCardDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    padding: 20,
    borderRadius: 20,
    maxWidth: 350,
    minWidth: 295,
    marginBottom: 50,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  huntClubTextDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    fontSize: 18,
  },
  huntClubDescriptionDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
    fontSize: 14,
    maxWidth: 300,
  },
  rxMazeCardDark: {
    backgroundColor: colors.darkThemeLightGreyBackground,
    padding: 20,
    borderRadius: 20,
    maxWidth: 350,
    minWidth: 295,
    marginBottom: 50,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  rxMazeTextDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkThemeWhiteText,
    fontSize: 18,
  },
  borderDark: {
    borderRightStyle: 'solid',
    borderRightWidth: 2,
    borderRightColor: colors.lightThemeWhite,
    marginLeft: 30,
  },
});

export default styles;
