/* eslint-disable max-len */
import React from 'react';
import FaqContainer from '../containers/faq';
import {Helmet} from 'react-helmet';

/**
 * Faq
 */
class Faq extends React.Component {
  /**
   * Lifecyle method
   * @return {component}container
   */
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Frequently Asked Questions (FAQ) - Bitz LLC</title>
          <meta
            name="description"
            content="Before you shoot me an email, take a moment to see if one of my previous clients has asked the same question. It could be just the response you’re looking for!"
          />
          <meta
            name="keywords"
            content="bitz llc support, bitz llc frequently asked questions, website support, mobile support, IT support, web and mobile help in richmond virginia"
          />
        </Helmet>
        <FaqContainer />
      </div>
    );
  }
}

export default Faq;
