const styles = (theme) => ({
  appDevelopmentImage: {
    height: 130,
    marginTop: 50,
  },
  appBackgroundSvg: {
    height: 150,
  },
  storeCard: {
    padding: 20,
  },
  launch: {
    position: 'relative',
  },
  launchImage: {
    height: 400,
    position: 'absolute',
    bottom: 0,
  },
  gridWrapper: {
    paddingBottom: 50,
  },
  webDevelopmentImage: {
    height: 100,
    marginTop: 75,
  },
});

export default styles;
