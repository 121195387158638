import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  rootDark: {
    paddingBottom: 16,
    flexGrow: 1,
    borderRadius: 0,
    backgroundColor: colors.darkThemeMediumGreyBackground,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  linkDark: {
    'textDecoration': 'none',
    'fontFamily': fonts.base,
    'fontSize': 14,
    'color': colors.darkThemeWhiteText,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bitzTitleDark: {
    fontFamily: fonts.base,
    fontWeight: 600,
    marginTop: 25,
    color: colors.darkPrimary,
    width: 70,
  },
});

export default styles;
