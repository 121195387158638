import {fonts, colors} from '../../../../../theme';

const styles = (theme) => ({
  heroBackgroundDark: {
    backgroundColor: colors.darkThemeDarkGreyBackground,
    paddingTop: 70,
    paddingBottom: 0,
  },
  heroHeadlineDark: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 36,
    fontFamily: fonts.base,
    fontWeight: 600,
    color: colors.darkPrimary,
    display: 'block',
    margin: 'auto',
    marginLeft: 20,
    marginRight: 20,
  },
  heroMissionDark: {
    marginTop: 10,
    marginBottom: 50,
    maxWidth: 540,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    fontSize: 16,
    display: 'block',
    margin: 'auto',
    fontFamily: fonts.base,
    color: colors.darkThemeWhiteText,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },
});

export default styles;
